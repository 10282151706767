<template>
  <div id="header-box">
    <div id="header-welcome">
      <div class="container welcome">
        <span>欢迎浏览杭州谱地新能源科技有限公司官网网站</span>
        <div>
          <span>咨询热线：<b>0571-88965202</b></span>
          <span style="margin-left:20px;">手机：<b>18969065575</b></span>
        </div>
      </div>
    </div>
    <div id="header-tool">
      <div class="container header-tool-list">
        <div id="header-logo">
          <el-image class="logo" src="./logo.png"></el-image>
        </div>
        <!-- <div id="header-search">
          <input class="search-input" type="text" placeholder="请搜索产品"/>
          <div class="search-btn">
            <i class="el-icon-search"></i>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: "pcHeader",
        components: {},
        data(){
            return {
              navActive:"nav-active",
            }
        },
        methods(){

        }
    }
</script>
<style lang="scss" scoped="scoped">
  #header-box{
    width: 100vw;
    background-color:#2C2B2B;
    margin: 0 auto;
  }
  #header-welcome{
    width: 100%;
    display:inline-flex;
    justify-content: center;
    background-color: #272526;
    .welcome{
      display:inline-flex;
      justify-content: space-between;
      font-size: 12px;
      color: #7C98A2;
      height: 30px;
      line-height: 30px;
      color: #B3B3B3;
    }
  }

  #header-tool{
    width: 100%;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    height: 135px;
    .header-tool-list{
      display:inline-flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  #header-logo{
    width: 229px;
    .logo{
      width: 100%;
    }
  }
  #header-search{
    display:inline-flex;
    align-items: center;
    width: 33.333333%;
    .search-input{
      border: none;
      box-shadow: none;
      padding: 12px 10px;
      height: 38px;
      background-color: #3a3839;
      border-right: none;
      color: #fff;
      width: 100%;
      flex-shrink: 1;
    }
    .search-btn{
      width: 52px;
      height: 38px;
      background-color: #1BAA39;
      flex-shrink: 0;
      display:inline-flex;
      justify-content: center;
      align-items: center;
      color:white;
      .el-icon-search{
        font-size: 16px;
      }
    }
  }

  .container{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }

</style>