<template>
  <div class="home" ref="boxView">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
      <div class="left">
        <div class="categories">
          <div class="title">新闻中心</div>
          <div class="nav-list" v-for="(v,k) in navList" :key="k">
            <div class="atitle" @click="goList(v,k)">> {{v.name}}</div>
            <div class="alists" v-if="v.show">
              <div class="alist" v-for="(item,index) in v.list" :key="index" @click="goAlist(k,index)">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list" v-if="contActive===2">
          <div class="title">PCBA工艺的应用范围及其组装时的考虑因素</div>
          <div class="time">2024-03-05  11:36:58</div>
          <div class="cont">随着科技的发展，人们的生产技术也得到了提高，拥有了不少新的技术工艺。那么大家知道PCBA工艺是什么吗？它有哪些实际应用呢？我们在进行PCBA组装时又需要考虑哪些问题呢？下面就跟随小编一起看一下吧。以下关于“PCBA工艺的应用范围及其组装时的考虑因素”的介绍。</div>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new1.png"/>
          <div class="title1">【介绍不同类型的PCB板的PCBA工艺】</div>
          <div class="title2">1.单面SMT贴装</div>
          <div class="cont1">将焊膏添加到组件垫，PCB裸板的锡膏印刷完成之后，经过回流焊贴装其相关电子元器件，然后进行回流焊焊接。</div>
          <div class="title2">2.单面DIP插装</div>
          <div class="cont1">需要进行插件的PCB板经生产线工人插装电子元器件之后过波峰焊，焊接固定之后剪脚洗板即可，但是波峰焊生产效率较低。</div>
          <div class="title2">3.单面混装</div>
          <div class="cont1">PCB板进行锡膏印刷，贴装电子元器件后经回流焊焊接固定，质检完成之后进行DIP插装，然后进行波峰焊焊接或是手工焊接，如果通孔元器件较少，建议采用手工焊接。</div>
          <div class="title2">4.单面贴装和插装混合</div>
          <div class="cont1">有些PCB板是双面板，一面贴装，另一面进行插装。贴装和插装的工艺流程跟单面加工是一样的，但PCB板过回流焊和波峰焊需要使用治具。</div>
          <div class="title2">5.双面SMT贴装</div>
          <div class="cont1">某些PCB板设计工程师为了保证PCB板的美观性和功能性，会采用双面贴装的方式。其中A面布置IC元器件，B面贴装片式元器件。充分利用PCB板空间，实现PCB板面积小化。</div>
          <div class="title2">6.双面混装</div>
          <div class="cont1">双面混装有以下两种方式：</div>
          <div class="cont1">第一种方式PCBA组装三次加热，效率较低，且使用红胶工艺波峰焊焊接合格率较低不建议采用。</div>
          <div class="cont1">第二种方式适用于双面SMD元件较多，THT元件很少的 情况，建议采用手工焊。若THT元件较多的情况，建议采用波峰焊。</div>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new2.png"/>
          <div class="title1">【PCBA的组装要考虑哪些问题】</div>
          <div class="cont">焊膏印刷工艺，主要解决的是焊膏印刷量一致性的问题（填充与转移），而不是每个焊点对焊膏量的需求问题。换句话说，焊膏印刷工艺解决的是一个焊接直通率波动的问题，而不是直通率高低的问题。要解决直通率高低的问题，关键在于焊膏分配，既通过焊盘、阻焊与钢网开窗的优化与匹配设计，对每个焊点按需分配焊膏量。当然，焊膏量的一致性与设计也有关联，PCB阻焊的不同设计提供的工艺能力指数不同。</div>
          <div class="title2">1.面积比</div>
          <div class="cont1">面积比是指钢网开窗面积与开窗孔壁面积之比，见下图：</div>
          <div class="title2">2.转移率</div>
          <div class="cont1">转移率是指印刷时钢网开窗内焊膏被沉积到焊盘上的比率，用实际转移的焊膏量与钢网开窗体积之比表示。</div>
          <div class="title2">3.面积比对转移率的影响</div>
          <div class="cont1">面积比是影响焊膏转移的重要因素，工程上一般要求面积比大于0.66，在此条件下可获得70%以上的转移率，见下图：</div>
          <div class="title2">4.面积比对设计的要求</div>
          <div class="cont1">面积比对钢网的设计有要求，主要影响精细间距元件。为了保证微细焊盘钢网开窗的面积比要求，钢网的厚度必须符合面积比的要求。这样对需要焊膏量比较多的元件，就需要通过增加钢网开窗面积的方式增加焊膏量—这就要求焊盘周围变形有空间，这是元件间距设计的一个主要考虑因素。</div>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new3.png"/>
        </div>
        <div class="list" v-if="contActive===3">
          <div class="title">PCB布局走线的最优法则</div>
          <div class="time">2024-03-06  16:21:34</div>
          <div class="cont">盲目的拉线，拉了也是白拉！</div>
          <div class="cont">有些小伙伴在PCB布线时，板子到手就是干，由于前期分析工作做的不足，或者压根就没做，导致后期处理时举步维艰。</div>
          <div class="cont">比如电源线、杂线拉完了，却漏掉一组重要的信号线，导致这组线没办法同组同层，甚至都没有完整的参考平面，需要对前面的布线工作做大修改才能完成，费时费力。</div>
          <div class="cont">如果将PCB板比作我们的城市，元器件就像鳞次栉比的各类建筑，信号线便是城里的大街小巷、天桥环岛，每条道路的出现都是有它的详细规划，布线亦是如此。</div>
          <div class="title1">一、布线有限次序要求</div>
          <div class="title2">1、关键信号线优先</div>
          <div class="cont1">电源、摸拟小信号、高速信号、时钟信号和同步信号等关键信号优先。</div>
          <div class="title2">2.布线密度优先原则</div>
          <div class="cont1">从单板上连接关系最复杂的器件着手布线。从单板上连线最密集的区域开始布线。</div>
          <div class="title2">3.关键信号处理注意事项</div>
          <div class="cont1">尽量为时钟信号、高频信号、敏感信号等关键信号提供专门的布线层，并保证其最小的回路面积。必要时应采取屏蔽和加大安全间距等方法。保证信号质量。</div>
          <div class="title2">4.阻抗控制</div>
          <div class="cont1">有阻抗控制要求的网络应布置在阻抗控制层上，须避免其信号跨分割。</div>
          <div class="title1">二、布线串扰控制</div>
          <div class="title2">1、3W原则释义</div>
          <div class="cont1">线与线之间的距离保持3倍线宽。是为了减少线间串扰，应保证线间距足够大，如果线中心距不少于3倍线宽时，则可保持70%的线间电场不互相干扰，称为3W规则。</div>
          <div class="title2">2.串扰控制</div>
          <div class="cont1">串扰（CrossTalk)是指PCB上不同网络之间因较长的平行布线引起的相互干扰，主要是由于平行线间的分布电容和分布电感的作用。</div>
          <div class="title2">克服串扰的主要措施是</div>
          <div class="cont1">● 加大平行布线的间距，遵循3W规则；</div>
          <div class="cont1">● 在平行线间插入接地的隔离线；</div>
          <div class="cont1">● 减小布线层与地平面的距离。</div>
          <div class="title1">三、布线的一般规则要求</div>
          <div class="title2">1、相邻平面走线方向成正交结构</div>
          <div class="cont1">避免将不同的信号线在相邻层走成同一方向，以减少不必要的层间窜扰；当由于板结构限制（如某些背板）难以避免出现该情况，特别是信号速率较高时，应考虑用地平面隔离各布线层，用地信号线隔离各信号线。</div>
          <div class="title2">2.小的分立器件走线须对称</div>
          <div class="cont1">间距比较密的SMT焊盘引线应从焊盘外部连接，不允许在焊盘中间直接连接。</div>
          <div class="title2">3.环路最小规则</div>
          <div class="cont1">信号线与其回路构成的环面积要尽可能小，环面积越小，对外的辐射越少，接收外界的干扰也越小。</div>
          <div class="title2">4.不出现STUB</div>
          <div class="cont1">走线不允许出现STUB。</div>
          <div class="title2">5、同一网络布线宽度一致</div>
          <div class="cont1">同一网络的布线宽度应保持一致，线宽的变化会造成线路特性阻抗的不均匀，当传输的速度较高时会产生反射。</div>
          <div class="cont1">在某些条件下，如接插件引出线，BGA封装的引出线类似的结构时，因间距过小可能无法避免线宽的变化，应该尽量减少中间不一致部分的有效长度。</div>
          <div class="title2">6、防止信号线在不同层间形成自环</div>
          <div class="cont1">在多层板设计中容易发生此类问题，自环将引起辐射干扰。</div>
          <div class="title2">7、避免锐角、直角</div>
          <div class="cont1">PCB设计中应避免产生锐角和直角，产生不必要的辐射，同时PCB生产工艺性能也不好。</div>
          <div class="cont1" style="text-align: right;color:#999;font-size:14px;">来源：记得城</div>
          <div class="cont1" style="text-align: right;color:#999;font-size:14px;margin-top:0px!important;">版权归原作者所有，如有侵权，请联系我们删除，谢谢！</div>
        </div>
        <div class="list" v-if="contActive===1">
          <div class="title">转型——在5G时代茁壮成长</div>
          <div class="time">2024-03-04  16:48:08</div>
          <div class="cont">在2019年中国国际信息通信展览会上，随着工信部宣布：5G商用正式启动！5G时代正式进入了千万家百姓的生活中。中国移动，中国电信，中国联通也正式发布了各自的5G套餐。</div>
          <div class="cont">目前，全国首批50个城市正式开启5G商用，这些城市包括北京、天津、上海、重庆4个直辖市以及太原、厦门、成都等46个城市。中国工程院院士邬贺铨在展览会上表示：“3G的出现带火了移动电子商务和社交应用，4G激发了视频业务和移动支付，5G将激活超高清视频和VR（虚拟现实）/AR（增强现实）并推动AIoT（人工智能物联网）发展。”</div>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new7.png"/>
          <div class="title1">为什么我们要转型？</div>
          <div class="cont1">近来随着国家政策支持，5G技术的推动，居民人均收入和人均支出提高、消费主力人群的变化，对智能产品的需求日益增加，中国智能硬件产业不断升级优化发展极快即将跨入万亿市场，而我司经过长时间对我司经营的汽车智能产品是市场上其他智能消费类电子的行业发展趋势进行调研、解读、分析，发现车载智能硬件行业一直处于比较成熟稳定的阶段，技术很难进行革新，市场热度减退，最终决定转型，将重心放在物联网系统研发销售、智能制造的配套服务方面；</div>
          <div class="title1"> 我们将要做什么?</div>
          <div class="cont1">答案是物联网系统研发销售与智能制造的配套服务。</div>
          <div class="cont1">什么是物联网？指的是将各种信息传感设备，如射频识别（RFID）装置、红外感应器、全球定位系统、激光扫描器等种装置与互联网结合起来而形成的一个巨大网络。其目的，是让所有的物品都与网络连接在一起，方便识别和管理。</div>
          <div class="cont1">物联网通常应用在哪些方面？其实消费电子、智能家居、智能交通、智能工业、智能医疗等领域都存在物联网的身影。像是我们平常使用的手机、智能马桶、人脸识别、联网的智能家居、智能车载产品都是物联网的表现。</div>
          
          <div class="cont1">那什么是智能制造呢？智能制造是一种由智能机器和人类专家共同组成的人机一体化智能系统，它在生产制造过程中能够代替人进行各种智能活动。比如，分析、推理、判断、构思和决策等等。</div>
          <div class="cont1">当物联网和智能制造遇到5G，就像正在成长的树木遇到肥沃的土壤。5g网络具备强大的通讯和带宽能力，能够满足物联网应用高速稳定、覆盖面广等需求。使车联网、智能家居、智慧医疗、智能工业以及智慧城市更快更好地呈现在我们面前。</div>
          <div class="cont1">想象一下，清晨起床，你的5G手机会自动控制家里的智能家居设备，缓缓调节室内光线，播放悠扬的音乐，将你唤醒。出门坐上车，车载新风系统为你实时监测车内空气，默默释放负氧离子，净化车内空气。到了工作的地方，人与智能制造机械高效率地进行生产.......这就是我们想要的未来。</div>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new4.png"/>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new5.png"/>
          <img class="img" fit="cover" src="http://cdn.puditech.com/pd-website/new6.png"/>
        </div>
        <div class="list" v-if="contActive===4">
          <div class="title">携手共进，共筑辉煌——合作伙伴亿力篇</div>
          <div class="time">2024-03-12  11:25:49</div>
          <div class="cont">2024年，杭州谱地重点大型客户，浙江亿力机电的越南工厂进行全面量产。</div>
          <div class="cont">浙江亿力机电股份有限公司是洗车机行业龙头企业，其成立于1999年，占地120000+m³，位于浙江省嘉兴市嘉善县，专注清洁行业35+年，是一家专注于清洁器具及园林用具的研发、生产及营销、提供整体清洁解决方案的公司，以高压清洗机、吸尘器、消杀设备、蒸汽清洁、锂电清洁等清洁电器为主要产品，是国内拥有高技术水平、大型生产规模的高压清洗设备制造企业之一。</div>
          <img class="img" fit="cover" src="https://cdn.puditech.com/pd-website/version2/yl.png"/>
          <div class="cont1">亿力从创立之初就注重科技创新，拥有雄厚的技术研发实力，曾先后获得高新技术企业、嘉兴市企业技术中心、嘉善县高新技术研究开发中心等多项荣誉称号。公司通过了ISO9001、ISO14001、OHSAS18001、BSCI、两化融合管理体系认证。公司主要产品更是先后通过了欧共体CE、EMC认证，北美ETLUL、CSA认证，德国GS认证，日本PSE认证，墨西哥NOM，巴西INMETRO认证，澳洲RCM认证、南非SABS认证及CCC认证，拥有获百余项国家专利。</div>
        
          <div class="cont1">杭州谱地在2022年协助浙江亿力重点产品型号进行配套研发，项目历时六个月圆满完成研发交付，技术指标符合客户的要求，在2022年和2023年交付的批量产品中无任何客诉。后续杭州谱地的研发团队本着专业严谨、认真负责的态度多次参与协助浙江亿力新品开发，两家公司紧密合作，为清洁行业提供科技化、人性化的优势性产品。</div>
          <div class="cont1">杭州谱地为嵌入式系统研发和制造商，在汽车电子、工业电子、物联网等行业领域有着专业的水平和丰富的经验，我们开拓进取，致力于为更多的客户与伙伴提供优质的方案和制造服务。</div>
        </div>
        <div class="list" v-if="contActive===5">
          <div class="title">如何改善PCBA加工焊点失效情况？</div>
          <div class="time">2024-09-11  11:25:49</div>
          <div class="cont">随着电子产品小型化和精密化的发展，电子加工厂采用的PCBA加工线路板和组装密度越来越高，电路板中的焊点越来越小，产品体积越来越小的同时也出现各种焊点失效的情况，越来越小的焊点所承载的机械、电气和热力学负载却越来越重，对稳定性的要求也日益提高。</div>
          <div class="cont">实际ODM/OEM加工过程中会遇到各种PCBA焊点失效的问题，工程师有必要分析并找出原因，以避免再次发生焊点故障。那么焊点失效主要有哪些原因呢？</div>
          <img class="img" fit="cover" src="https://cdn.puditech.com/pd-website/news1.png"/>
          <div class="cont1">PCBA加工焊点失效的主要原因:</div>
          <div class="cont1">1、元器件引脚不良:镀层、污染、氧化、共面。</div>
          <div class="cont1">2、PCB焊盘不良：镀层、污染、氧化、翘曲。</div>
          <div class="cont1">3、焊料质量缺陷:组成、杂质不达标、氧化。</div>
          <div class="cont1">4、焊剂质量缺陷：低助焊性、高腐蚀、低SIR.</div>
          <div class="cont1">5、工艺参数控制缺陷：设计、控制、设备。</div>
          <div class="cont1">6、其他辅助材料缺陷：胶粘剂、清洗剂。</div>
          <div class="cont1">那有没有增加PCBA焊点的稳定性的方法呢？</div>
          <div class="cont1">PCBA焊点的稳定性实验包括稳定性实验和分析。</div>
          <div class="cont1">一方面，其目的是评估和识别PCBA集成电,路器件的稳定性水平，为整机的稳定性设计提供参数。</div>
          
          <div class="cont1">另一方面，在PCBA加工过程中，有必要提高焊点的稳定性。这就需要对失效产品进行分析，找出失效模式，分析失效原因。目的是修正和改进设计工艺、结构参数、焊接工艺，提高 PCBA线路板加工厂成品率。PCBA焊点的失效模式是预测其循环寿命和建立其数学模型的基础。</div>
          <img class="img" fit="cover" src="https://cdn.puditech.com/pd-website/news2.png"/>
          <div class="cont1">掌握多年OEM/ODM设计及SMT加工经验，设计组装测试一条龙服务，有效降低PCBA焊点失效概率，专业的团队为您解决后顾之忧！</div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>
<script>
// @ is an alias to /src

import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      navList:[
        {id:1,name:"公司新闻",show:false,list:["转型——在5G时代茁壮成长","携手共进，共筑辉煌——合作伙伴亿力篇"]},
        {id:2,name:"行业新闻",show:false,list:["PCBA工艺的应用范围及其组装时的考虑因素","PCB布局走线的最优法则","如何改善PCBA加工焊点失效情况？"]},
      ],
      contActive:1,
      currentIndex:4,
      proShow:false,
    }
  },
  mounted() {
    this.contActive=parseInt(this.$route.query.contActive)
  },
  methods: {
    goList(v,k){
      // console.log(e)
      this.navList[k].show = !this.navList[k].show
      this.contActive=v.id
    },
    goAlist(k,index){
      console.log(k)
      console.log(index)
      if(k===0){
        if(index===0){
          this.contActive=1
        }else if(index===1){
          this.contActive=4
        }
      }
      if(k===1){
        if(index===0){
          this.contActive=2
        }else if(index===1){
          this.contActive=3
        }else if(index===2){
          this.contActive=5
        }
      }
    },
    showActive(val){
      this.contActive=val
      // console.log(this.contActive)
    },
    showIndex(val){
      this.currentIndex=val
      console.log(this.currentIndex)
    },
  }
}
</script>
<style lang="scss" scoped>

.home {
  margin: 0;
  padding: 0;
  .content{
    width: 100%;
    display:flex;
    justify-content: center;
    padding:50px 0;
    margin:0 auto;
    .left{
      width:20%;
      .title{
        height:60px;
        line-height:60px;
        border-bottom: 1px solid #eee;
        font-size:30px;
        margin-bottom:20px;
      }
      .categories{
        width:200px;
        
        .nav-list{
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
          .atitle{
            font-weight:600;
            font-size:15px;
          }
          .alists{
            // height:75px;
            .alist{
              width:100%;
              line-height:20px;
              padding-left: 20px;
              margin-bottom:10px;
              // text-align: center;
            }
          }
          
        }
      }
    }
    .right{
        padding:0 50px;
        width:70%;
        margin-right:10%;
        .list{
          margin-bottom:20px;
          line-height:40px;
          .title{
            font-size:32px;
          }
          .time{
            font-size:16px;
            color:#999;
          }
          .img{
            margin-top:20px;
            width:80%;
            margin-left: 10%;
          }
          .title1{
            font-size:26px;
            margin-top:20px;
          }
          .title2{
            font-size:20px;
            margin-top:20px;
          }
          .cont{
            font-size:16px;
            line-height:30px;
            margin-top:20px;
          }
          .cont1{
            font-size:16px;
            line-height:30px;
            margin-top:10px;
            padding-left:20px;
          }
        }
    }
  }
  .fs20{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .fs18{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .content{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
  .right{
    @media (min-width: 769px){
      min-height: 370px;
    }
    @media (min-width: 992px){
      min-height:420px;
    }
    @media (min-width: 1200px){
      min-height: 470px;
    }
  }
}
</style>
