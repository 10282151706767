<template>
  <div class="home-box bar">
    
  </div>
</template>

<script>

export default {
  name: "Home.vue",
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
  
  }
};
</script>

<style scoped lang="scss">

</style>
