<template>
  <div class="home" ref="boxView">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
      <div class="left">
        <div class="categories">
          <div class="nav-list" v-for="(v,k) in navList" :key="k" @click="goList(v)">>  {{v.name}}</div>
        </div>
      </div>
      <div class="right">
        <div class="list">
            <div class="detail">
                公司：杭州谱地新能源科技有限公司
            </div>
            <div class="detail">
                地址：浙江省杭州市余杭区中泰街道绿泰路5号甲楠智创中心A座3楼
            </div>
            <div class="detail">
                电话：0571-88965202
            </div>
            <div class="detail">
                手机：18969065575
            </div>
            <div class="detail">
                E-Mail:shofees@vip.163.com
            </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>
<script>
// @ is an alias to /src

import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      navList:[
        {id:1,name:"联系我们"},
      ],
      contActive:1,
      currentIndex:5,
      proShow:false,
    }
  },
  mounted() {
  },
  methods: {
    goList(e){
      // console.log(e)
      this.contActive=e.id
    },
    showActive(val){
      this.contActive=val
      // console.log(this.contActive)
    },
    showIndex(val){
      this.currentIndex=val
      console.log(this.currentIndex)
    },
    proSub(e){
        this.proShow=e
    }
  }
}
</script>
<style lang="scss" scoped>

.home {
  margin: 0;
  padding: 0;
  .content{
    width: 100%;
    display:flex;
    justify-content: center;
    padding:50px 0;
    margin:0 auto;
    .left{
      width:20%;
      .title{
        height:60px;
        line-height:60px;
        border-bottom: 1px solid #eee;
        font-size:30px;
        margin-bottom:20px;
      }
      .categories{
        width:200px;
        .nav-list{
          height:40px;
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
      }
    }
    .right{
        padding:0 50px;
        width:70%;
        margin-right:10%;
        .list{
          margin-bottom:20px;
          height:40px;
          line-height:40px;
        }
    }
  }
  .fs20{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .fs18{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .content{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
  .right{
    @media (min-width: 769px){
      min-height: 370px;
    }
    @media (min-width: 992px){
      min-height:420px;
    }
    @media (min-width: 1200px){
      min-height: 470px;
    }
  }
}
</style>
