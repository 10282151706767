<template>
  <div class="home" ref="boxView" id="top">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
      <div class="left">
        <div class="categories">
          <div class="title">产品分类</div>
          <div class="nav-list" v-for="(v,k) in navList" :key="k" >
            <div class="atitle" @click="goList(v,k)">> {{v.name}}</div>
            <div class="alists" v-if="v.show">
              <div class="alist" v-for="(item,index) in v.list" :key="index" @click="goAlist(k,index)">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title" v-if="contActive===1||contActive===6||contActive===3||contActive===4||contActive===27">产品</div>
        <div class="title" v-else>产品详情介绍</div>
        <!-- 消费电子零部件 -->
        <div class="lists" v-if="contActive===6">
            <div class="list" v-for="(v,k) in list6" :key="k" @click="goBuy(v,k)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <div class="lists" v-else-if="contActive===31">
          <div class="back" @click="backBuy">返回</div>
          <div class="productTitle">多功能移动电源PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/dy.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：没有了</div>
            <div class="next" @click="next">下一篇：雾化器PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===32">
          <div class="back" @click="backBuy">返回</div>
          <div class="productTitle">雾化器PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/whq.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：多功能移动电源PCBA</div>
            <div class="next" @click="next">下一篇：智能紫外杀菌灯PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===33">
          <div class="back" @click="backBuy">返回</div>
          <div class="productTitle">智能紫外杀菌灯PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/zw.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：雾化器PCBA</div>
            <div class="next" @click="next">下一篇：Power 7 计时器PCBA</div>
          </div>
        </div>
        <!-- 工业电子零部件 -->
        <div class="lists" v-if="contActive===1">
            <div class="list" v-for="(v,k) in list2" :key="k" @click="goDetails(v)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <!-- 详情 -->
        <div class="lists" v-else-if="contActive===11">
          <div class="back" @click="back1">返回</div>
          <div class="productTitle">Power 7 计时器PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/power7.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：智能紫外杀菌灯PCBA</div>
            <div class="next" @click="next">下一篇：Slow Timer计时器PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===24">
          <div class="back" @click="back1">返回</div>
          <div class="productTitle">Slow Timer计时器PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/slowTimer.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：Power 7 计时器PCBA</div>
            <div class="next" @click="next">下一篇：潜航器电机驱动PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===13">
          <div class="back" @click="back1">返回</div>
          <div class="productTitle">潜航器电机驱动PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/ganghangqi.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：Slow Timer计时器PCBA</div>
            <div class="next" @click="next">下一篇：无人机控制板PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===14">
          <div class="back" @click="back1">返回</div>
          <div class="productTitle">无人机控制板PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/wurenji.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：潜航器电机驱动PCBA</div>
            <div class="next" @click="next">下一篇：智能水表PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===25">
          <div class="back" @click="back1">返回</div>
          <div class="productTitle">智能水表PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/znsb.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：无人机控制板PCBA</div>
            <div class="next" @click="next">下一篇：大灯控制器PCBA</div>
          </div>
        </div>
        <!-- 汽车电子零部件 -->
        <div class="lists" v-else-if="contActive===3">
            <div class="list" v-for="(v,k) in list4" :key="k" @click="goDetail(v,k)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <div class="lists" v-else-if="contActive===15">
          <div class="back" @click="back2">返回</div>
          <div class="productTitle">大灯控制器PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/lightControl.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：智能水表PCBA</div>
            <div class="next" @click="next">下一篇：汽车360全景影像系统控制板PCBA</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===12">
          <div class="back" @click="back2">返回</div>
          <div class="productTitle">汽车360全景影像系统控制板PCBA</div>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/360.jpg"/>
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/p7.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：大灯控制器PCBA</div>
            <div class="next" @click="next">下一篇：基于GD32F光电触发的拍照系统</div>
          </div>
        </div>
        <!-- 物联网解决方案 -->
        <div class="lists" v-else-if="contActive===2">
            <div class="list" v-for="(v,k) in list1" :key="k" @click="goFa(v)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <div class="lists" v-else-if="contActive===5">
            <div class="list" v-for="(v,k) in list5" :key="k" @click="goFa2(v)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <div class="lists" v-else-if="contActive===7">
            <div class="list" v-for="(v,k) in list7" :key="k" @click="goFa3(v)">
                <img :src="v.src"/>
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <!-- 物联网详情 -->
        <div class="lists" v-else-if="contActive===21">
          <!-- <div class="back" @click="back3">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/GD32F.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：汽车360全景影像系统控制板PCBA</div>
            <div class="next" @click="next">下一篇：基于RS485通信的多屏显示系统</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===22">
          <!-- <div class="back" @click="back4">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/RS485.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：基于GD32F光电触发的拍照系统</div>
            <div class="next" @click="next">下一篇：基于WIFI网络多级互联灯控系统</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===23">
          <!-- <div class="back" @click="back5">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/hldk.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：基于RS485通信的多屏显示系统</div>
            <div class="next" @click="next">下一篇：智能紫外线杀菌灯控制方案</div>
          </div>
        </div>
        <!-- 嵌入式详情 -->
        <div class="lists" v-else-if="contActive===34">
          <!-- <div class="back" @click="back3">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/ultraviolet.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：基于WIFI网络多级互联灯控系统</div>
            <div class="next" @click="next">下一篇：智能洗拖地一体机控制方案</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===35">
          <!-- <div class="back" @click="back4">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/wash.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：智能紫外线杀菌灯控制方案</div>
            <div class="next" @click="next">下一篇：LED幻彩灯喷雾化妆镜方案</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===36">
          <!-- <div class="back" @click="back5">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/led.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：智能洗拖地一体机控制方案</div>
            <div class="next" @click="next">下一篇：医用净水器带屏显控制方案</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===37">
          <!-- <div class="back" @click="back5">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/water.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：LED幻彩灯喷雾化妆镜方案</div>
            <div class="next" @click="next">下一篇：充电宝多功能输出解决方案</div>
          </div>
        </div>
        <div class="lists" v-else-if="contActive===38">
          <!-- <div class="back" @click="back5">返回</div> -->
          <img class="img" src="https://cdn.puditech.com/pd-website/version2/electric.jpg"/>
          <div class="page">
            <div class="pre" @click="pre">上一篇：医用净水器带屏显控制方案</div>
            <div class="next" @click="next">下一篇：什么是无电电器</div>
          </div>
        </div>
        <!-- 外贸小电器 -->
        <div class="lists" v-else-if="contActive===4">
          <div class="list" v-for="(v,k) in list3" :key="k" @click="goElectric(v,k)">
              <img :src="v.src"/>
              <div class="name">{{v.name}}</div>
          </div>
          <!-- <div class="page">
            <div class="pre" @click="pre">上一篇：基于WIFI网络多级互联灯控系统</div>
            <div class="next" @click="next">下一篇：智能紫外杀菌宝</div>
          </div> -->
        </div>
        <div class="lists" v-else-if="contActive===27">
          <div class="list" v-for="(v,k) in list8" :key="k" @click="goElectric2(v,k)">
              <img :src="v.src"/>
              <div class="name">{{v.name}}</div>
          </div>
          <!-- <div class="page">
            <div class="pre" @click="pre">上一篇：无电电器</div>
            <div class="next" @click="next">下一篇：没有了</div>
          </div> -->
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>
<script>
// @ is an alias to /src

import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      navList:[
        {id:1,name:"电路板OEM代工",show:false,list:["消费电子零部件","工业电子零部件","汽车电子零部件"]},
        {id:2,name:"物联网系统方案",show:false,list:["基于GD32F光电触发的拍照系统","基于RS485通信的多屏显示系统","基于WIFI网络多级互联灯控系统"]},
        {id:3,name:"嵌入式硬件方案",show:false,list:["智能紫外线杀菌灯控制方案","智能洗拖地一体机控制方案","LED幻彩灯喷雾化妆镜方案","医用净水器带屏显控制方案","充电宝多功能输出解决方案"]},
        {id:4,name:"外贸优势小电器",show:false,list:["无电电器","智能紫外杀菌宝"]},
      ],
      contActive:1,
      currentIndex:3,
      proShow:false,
      image1:require('@/assets/blis1.png'),
      image2:require('@/assets/blis2.png'),
      image3:require('@/assets/blis3.png'),
      image4:require('@/assets/blis4.png'),
      image5:require('@/assets/blis5.png'),
      image6:require('@/assets/blis6.png'),
      image7:require('@/assets/blis7.png'),
      image8:require('@/assets/mq1.png'),
      image9:require('@/assets/mq2.png'),
      image10:require('@/assets/mq3.png'),
      image11:require('@/assets/mq4.png'),
      list1:[
        {
            src:"https://cdn.puditech.com/pd-website/version2/GD32F-main.jpg",
            name:"基于GD32F光电触发的拍照系统"
        }
      ],
      list5:[
        {
            src:"https://cdn.puditech.com/pd-website/version2/RS485-main.jpg",
            name:"基于RS485通信的多屏显示系统"
        }
      ],
      list7:[
        {
            src:"https://cdn.puditech.com/pd-website/version2/hldk-main.jpg",
            name:"基于WIFI网络多级互联灯控系统"
        }
      ],
      // 消费电子零部件
      list6:[
        {
            src:"https://cdn.puditech.com/pd-website/version2/dy-main.jpg",
            name:"多功能移动电源PCBA"
        },
        {
            src:require('@/assets/two/7.png'),
            name:"雾化器PCBA"
        },
        {
            src:"https://cdn.puditech.com/pd-website/version2/zw-main.jpg",
            name:"智能紫外杀菌灯PCBA"
        }
      ],
      // 工业电子零部件
      list2:[
        {
            src:require('@/assets/two/1.png'),
            name:"Power 7 计时器PCBA"
        },
        {
            src:require('@/assets/two/2.png'),
            name:"Slow Timer计时器PCBA"
        },
        {
            src:require('@/assets/two/5.png'),
            name:"潜航器电机驱动PCBA"
        },
        {
            src:require('@/assets/two/6.png'),
            name:"无人机控制板PCBA"
        },
        {
            src:require('@/assets/two/8.png'),
            name:"智能水表PCBA"
        },
      ],
      // 汽车电子零部件
      list4:[
        {
            src:require('@/assets/two/3.png'),
            name:"大灯控制器PCBA"
        },
        {
            src:require('@/assets/two/4.png'),
            name:"汽车360全景影像系统控制板PCBA"
        }
      ],
      list3:[
        {
            src:require('@/assets/four/1.png'),
            name:"1、什么是无电电器,点击了解"
        },
        {
            src:require('@/assets/four/2.png'),
            name:"2、如何选购产品,点击了解"
        },
        {
            src:require('@/assets/four/3.png'),
            name:"3、探寻电源平台性能,点击了解"
        },
        {
            src:require('@/assets/four/4.png'),
            name:"4、如何实现应用创新,点击了解"
        },
        {
            src:require('@/assets/four/5.png'),
            name:"EE充电头",
        },
        {
            src:require('@/assets/four/7.png'),
            name:"无电7MM热胶枪"
        },
        {
            src:require('@/assets/four/8.png'),
            name:"无电补水彩妆镜"
        },
        {
            src:require('@/assets/four/9.png'),
            name:"无电弹匣研磨器"
        },
        {
            src:require('@/assets/four/10.png'),
            name:"无电定型梳"
        },
        {
            src:require('@/assets/four/11.png'),
            name:"无电卷发棒"
        },
        {
            src:require('@/assets/four/12.png'),
            name:"无电扩音趣玩音箱"
        },
        {
            src:require('@/assets/four/13.png'),
            name:"无电美发夹"
        },
        {
            src:require('@/assets/four/14.png'),
            name:"无电巧妍美眼仪"
        },
        {
            src:require('@/assets/four/15.png'),
            name:"无电双用双温探针"
        },
        {
            src:require('@/assets/four/16.png'),
            name:"无电细雾喷枪"
        },
        {
            src:require('@/assets/four/17.png'),
            name:"无电悦动幻彩灯"
        },
        {
            src:require('@/assets/four/18.png'),
            name:"无电自动电烙铁"
        }
      ],
      list8:[
        {
            src:require('@/assets/four/chezai.jpg'),
            name:"车载杀菌宝",
            id:17
        },
        {
            src:require('@/assets/four/jiayong.jpg'),
            name:"家用杀菌宝",
            id:18
        }
      ]
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.contActive=parseInt(this.$route.query.contActive)
    console.log(this.contActive)
  },
  methods: {
    goList(v,k){
      console.log(v)
      this.navList[k].show = !this.navList[k].show
      if(k===2){
        this.contActive=34
      }else if(k===0){
        this.contActive=6
      }else if(k===1){
        this.contActive=21
      }else if(k===3){
        this.contActive=4
      }
    },
    goAlist(k,index){
      console.log(k)
      console.log(index)
      if(k===0){
        if(index===0){
          this.contActive=6
        }else if(index===1){
          this.contActive=1
        }else if(index===2){
          this.contActive=3
        }
      }else if(k===1){
        if(index===0){
          this.contActive=21
        }else if(index===1){
          this.contActive=22
        }else if(index===2){
          this.contActive=23
        }
      }else if(k===2){
        if(index===0){
          this.contActive=34
        }else if(index===1){
          this.contActive=35
        }else if(index===2){
          this.contActive=36
        }else if(index===3){
          this.contActive=37
        }else if(index===4){
          this.contActive=38
        }
      }else if(k===3){
        if(index===0){
          this.contActive=4
        }else if(index===1){
          this.contActive=27
        }
      }
    },
    next(){
      if(this.contActive==31){
        this.contActive = 32
      }else if(this.contActive==32){
        this.contActive = 33
      }else if(this.contActive===33){
        this.contActive = 11
      }else if(this.contActive===11){
        this.contActive = 24
      }else if(this.contActive===24){
        this.contActive = 13
      }else if(this.contActive===13){
        this.contActive = 14
      }else if(this.contActive===14){
        this.contActive = 25
      }else if(this.contActive===25){
        this.contActive = 15
      }else if(this.contActive===15){
        this.contActive = 12
      }else if(this.contActive===12){
        this.contActive = 21
      }else if(this.contActive===21){
        this.contActive = 22
      }else if(this.contActive===22){
        this.contActive = 23
      }else if(this.contActive===23){
        this.contActive = 34
      }else if(this.contActive===34){
        this.contActive = 35
      }else if(this.contActive===35){
        this.contActive = 36
      }else if(this.contActive===36){
        this.contActive = 37
      }else if(this.contActive===37){
        this.contActive = 38
      }else if(this.contActive===38){
        this.$router.push({path:"/electric",query:{index:0}})
      }else if(this.contActive===4){
        this.contActive = 27
      }else if(this.contActive===27){
        this.contActive = 27
      }
      document.querySelector('#top').scrollIntoView(true)
    },
    pre(){
      if(this.contActive===31){
        this.contActive = 31
      }else if(this.contActive===32){
        this.contActive = 31
      }else if(this.contActive===33){
        this.contActive = 32
      }else if(this.contActive===11){
        this.contActive = 33
      }else if(this.contActive===24){
        this.contActive = 11
      }else if(this.contActive===13){
        this.contActive = 24
      }else if(this.contActive===14){
        this.contActive = 13
      }else if(this.contActive===25){
        this.contActive = 14
      }else if(this.contActive===15){
        this.contActive = 25
      }else if(this.contActive===12){
        this.contActive = 15
      }else if(this.contActive===21){
        this.contActive = 12
      }else if(this.contActive===22){
        this.contActive = 21
      }else if(this.contActive===23){
        this.contActive = 22
      }else if(this.contActive===38){
        this.contActive = 37
      }else if(this.contActive===37){
        this.contActive = 36
      }else if(this.contActive===36){
        this.contActive = 35
      }else if(this.contActive===35){
        this.contActive = 34
      }else if(this.contActive===34){
        this.contActive = 23
      }
      document.querySelector('#top').scrollIntoView(true)
    },
    back1(){
      this.contActive=1
    },
    back2(){
      this.contActive=3
    },
    back3(){
      this.contActive=2
    },
    back4(){
      this.contActive=5
    },
    back5(){
      this.contActive=7
    },
    backBuy(){
      this.contActive=6
    },
    goFa(){
      this.contActive=21
    },
    goFa2(){
      this.contActive=22
    },
    goFa3(){
      this.contActive=23
    },
    showActive(val){
      this.contActive=val
      // console.log(this.contActive)
    },
    showIndex(val){
      this.currentIndex=val
      console.log(this.currentIndex)
    },
    proSub(e){
        this.proShow=e
    },
    goBuy(v,k){
      if(k===0){
        this.contActive=31
      }else if(k===1){
        this.contActive=32
      }else if(k===2){
        this.contActive=33
      }
    },
    goDetail(v,k){
      if(k===0){
        this.contActive=15
      }else if(k===1){
        this.contActive=12
      }
    },
    goDetails(v){
      console.log(v)
      if(v.name=='Power 7 计时器PCBA'){
        this.contActive=11
      }else if(v.name=='潜航器电机驱动PCBA'){
        this.contActive=13
      }else if(v.name=='无人机控制板PCBA'){
        this.contActive=14
      }else if(v.name=='Slow Timer计时器PCBA'){
        this.contActive=24
      }else if(v.name=='智能水表PCBA'){
        this.contActive=25
      }
    },
    goElectric(v,k){
      console.log(k)
      console.log(v)
      this.$router.push({path:"/electric",query:{index:k}})
    },
    goElectric2(v,k){
      console.log(k)
      console.log(v)
      this.$router.push({path:"/electric",query:{index:v.id}})
    }
  }
}
</script>
<style lang="scss" scoped>

.home {
  margin: 0;
  padding: 0;
  .content{
    width: 100%;
    display:flex;
    justify-content: center;
    padding:50px 0;
    margin:0 auto;
    .left{
      width:20%;
      .title{
        height:60px;
        line-height:60px;
        border-bottom: 1px solid #eee;
        font-size:30px;
        margin-bottom:20px;
      }
      .categories{
        width:200px;
        .nav-list{
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
          .atitle{
            font-weight:600;
            font-size:15px;
          }
          .alists{
            // height:75px;
            .alist{
              width:100%;
              line-height:20px;
              padding-left: 20px;
              margin-bottom:10px;
              // text-align: center;
            }
          }
          
        }
      }
    }
    .right{
        padding:0 50px;
        width:70%;
        margin-right:10%;
        position: relative;
        .back{
          position: absolute;
          top:30px;
          right:10%;
          cursor: pointer;
          font-size: 16px;
        }
        .title{
            height:60px;
            line-height:60px;
            border-bottom: 1px solid #eee;
            font-size:30px;
            margin-bottom:20px;
        }
        .lists{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .list{
                margin-bottom:20px;
                margin-right:40px;
                img{
                    width:190px;
                    height:190px;
                    border-radius: 5px;
                }
                .name{
                    text-align: center;
                }
            }
            .page{
              width:100%;
              margin-top: 30px;
              display: flex;
              justify-content: space-between;
              .pre{
                padding:10px 15px;
                font-size:14px;
                background-color: #f8f6f6;
                border-radius: 5px;
                cursor: pointer;
              }
              .next{
                padding:10px 15px;
                font-size:14px;
                background-color: #f8f6f6;
                border-radius: 5px;
                cursor: pointer;
              }
            }
            .img{
              width:80%;
              // margin-left:%;
              margin-top:10px;
            }
            .title{
                width:100%;
                font-size:30px;
                font-weight: 600;
                text-align: center;
                letter-spacing: 2px;
                margin:0 auto;
            }
            
            .productTitle{
                width:100%;
                font-size:26px;
                font-weight: 500;
                text-align: center;
                margin:10px auto;
            }
            .title1{
                width:100%;
                font-size:26px;
                font-weight: 500;
                text-align: left;
                margin:20px auto;
            }
            .title2{
                width:100%;
                font-size:22px;
                font-weight: 500;
                text-align: left;
                margin:10px auto;
            }
            .cont1{
                width:100%;
                font-size:18px;
                text-align: left;
                padding:0 10px;
                margin:10px auto;
            }
            .image{
                width:70%;
                margin-left:15%;
                margin-top:10px;
            }
            .image2{
                width:70%;
                margin-left:15%;
                margin-top:10px;
            }
        }
    }
  }
  .fs20{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .fs18{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .content{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
  .right{
    @media (min-width: 769px){
      min-height: 370px;
    }
    @media (min-width: 992px){
      min-height:420px;
    }
    @media (min-width: 1200px){
      min-height: 470px;
    }
  }
}
</style>
