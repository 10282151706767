<template>
  <div class="footer">
    <div class="footer-left">
      <p>地址：浙江省杭州市余杭区中泰街道绿泰路5号甲楠智创中心A座3楼</p>
      <p>
        <span>邮编：311121</span>
        <span style="margin-left:20px">电话：0571-88965202</span>
        <span style="margin-left:20px">E-Mail：shofees@vip.163.com</span>
      </p>
      <p style="display:inline-flex;margin-top:10px;">
        Copyright © 杭州谱地新能源科技有限公司 版权所有
        <a style="margin-left:20px;color:white;display:flex;align-items:center;" href="http://beian.miit.gov.cn"><img style="width:20px;height:20px;margin-right:5px" src="https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"/>浙ICP备2021005693号</a>
      </p>
      <p style="margin-top:10px;">
        <a href="mailto:shofees@vip.163.com">
          <img src="http://cdn.puditech.com/pd-website/index-qyyx.gif"/>
        </a>
        <a href="javascript:;" style="margin-left:20px;">
          <img src="http://cdn.puditech.com/pd-website/index-qywx.gif"/>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "header",
  components: {},
  methods: {
    home() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style lang="scss" scoped="scoped">
.footer {
  text-align: center;
  background-color: #333;
  color: #fff;
  padding: 30px 0px 20px 0px;
  font-size: 12px;
  
}
</style>
