<template>
  <div class="home" ref="boxView">
    <pc-header></pc-header>
    <pc-nav  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div id="swipper-box">
      <el-carousel height="840px">
        <el-carousel-item v-for="(value,key) in banner" :key="key">
          <el-image style="width:100%;height:100%" fit="cover" :src="value.image"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="product-box">
      <div class="container">
        <div class="module-header product-header">
          <h2>产品中心</h2>
          <p>PRODUCTS</p>
        </div>

        <div class="product-list">
          <div class="product" v-for="(value,key) in products" :key="key" @click="goProduct(value,key)">
            <el-image class="product-image" :src="value.image" fit="cover"></el-image>
            <p class="product-title">{{value.name}}</p>
          </div>
        </div>
      </div>
    </div>

    <div id="memory-box">
      <div class="container">
        <div class="memory-header">
          <h2>我们的优势</h2>
          <p>OUR ADVANTAGE</p>
        </div>

        <div class="container memory-list">
          <div class="memory-row" style="perspective: 1000px">
            <div class="col-sm-6 col-md-6 advantage_col" data-move-y="200px" style="opacity: 1;transition: all 1s ease 0s, opacity 1.5s ease 0s;">
              <div class="col-sm-3 col-md-3">
                <a>
                  <img fit="cover" src="http://cdn.puditech.com/pd-website/zhizao.png"/>
                </a>
              </div>
              <div class="col-sm-9 col-md-9">
                <h4>
                  <a>完善的供应制造体系</a>
                </h4>
                <span>
                  1.团队丰富的设计经验;<br/>
                  2.优质的供货商;
                  3.专业的制板，焊接人员;<br/>
                  4.生产工艺多重把关;
                  5.贯彻执行ISO质量管理体系;
                </span>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 advantage_col" data-move-y="200px" style="opacity: 1;transition: all 1s ease 0s, opacity 1.5s ease 0s;">
              <div class="col-sm-3 col-md-3">
                <a>
                  <img fit="cover" src="http://cdn.puditech.com/pd-website/coding.png"/>
                </a>
              </div>
              <div class="col-sm-9 col-md-9">
                <h4>
                  <a>强大的研发技术实力</a>
                </h4>
                <span>
                  1.拥有经验丰富的PCB设计专家，为您量身定做可行性方案;<br/>
                  2.产品广泛应用于计算机、仪器表、汽车、LED、智能家居设备等领域;
                </span>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 advantage_col" data-move-y="200px" style="opacity: 1;transition: all 1s ease 0s, opacity 1.5s ease 0s;">
              <div class="col-sm-3 col-md-3">
                <a>
                  <img src="http://cdn.puditech.com/pd-website/chejian.png"/>
                </a>
              </div>
              <div class="col-sm-9 col-md-9">
                <h4>
                  <a>独立的试产实验车间</a>
                </h4>
                <span>
                  1.使用高精度、高效率贴片机器;<br/>
                  2.自动化流水线，完善的成产流程，可支持灵活加工;<br/>
                  3.各类监测设备，质量保障;
                </span>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 advantage_col" data-move-y="200px" style="opacity: 1;transition: all 1s ease 0s, opacity 1.5s ease 0s;">
              <div class="col-sm-3 col-md-3">
                <a>
                  <img src="http://cdn.puditech.com/pd-website/service.png"/>
                </a>
              </div>
              <div class="col-sm-9 col-md-9">
                <h4>
                  <a>专业的售后服务能力</a>
                </h4>
                <span>
                  1.自有专业工程师能解决复杂问题;<br/>
                  2.时刻关注客户需求，从客户角度出发;<br/>
                  3.极速处理问题，让客户放心;
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="about-box">
      <div class="container">
        <div class="company-about">
          <div class="about-title">
            <h2>公司介绍</h2>
            <span>ABOUT US</span>
          </div>
          <div class="about-content">
            <p>
              <img align="left" src="http://cdn.puditech.com/pd-website/company-image.jpg" alt="公司介绍"/>
            </p>

            <p class="about-contents">
              杭州谱地新能源科技有限公司创立于2010年，地处杭州未来科技城，是一家集工业电子零部件制造服务与技术创新型企业，在汽车电子零部件与物联网智能系统等新兴产业具有技术前瞻性和产品产业化优势。<br/>
              公司拥有稳定的研发与生产制造管理团队，自设先进的SMT自动化流水线和各类检测设备，并荣获国家高新企业、区级研发中心等荣誉以及IATF16949、ISO14001等资质和众多产品专利。
            </p>
            <p></p>
            <a href="/about" class="btn btn-info" role="button">更多关于</a>
          </div>
        </div>
      </div>
    </div>

    <div id="honor-box">
      <div class="container">
        <div class="module-header honor-header">
          <h2>荣誉墙</h2>
          <p>HONOR WALL</p>
        </div>
        <div class="honor-list">
          <div class="honor" v-for="(value,key) in honors" :key="key">
            <el-image class="honor-image" :src="value.image" fit="contain"></el-image>
            <p class="honor-title">{{value.name}}</p>
          </div>
        </div>
      </div>
    </div>

    <div id="news-box">
      <div class="container">
        <div class="module-header news-header">
          <h2>新闻中心</h2>
          <p>NEWS</p>
        </div>

        <div class="news-list">
          <div class="news" v-for="(value,key) in news" :key="key" @click="goNews(key)">
            <span :class="[value.type ? 'yellow_bg' : 'green_bg']">
              <strong>{{value.day}}</strong>
              <i>{{value.date}}</i>
            </span>
            <div class="newTitle">{{value.title}}</div>
            <br/>
            <em>
              {{value.content}}
            </em>
          </div>
        </div>
      </div>
    </div>

    <div id="photo-box">
      <div class="container">
        <div class="module-header photo-header">
          <h2>企业照片</h2>
          <p>COMPANY PHOTO</p>
        </div>
        <div class="photo-swaper">
          <el-carousel height="400px" :interval="4000" type="card">
            <el-carousel-item v-for="item in 24" :key="item">
              <el-image class="photo" fit="cover" :src="'http://cdn.puditech.com/pd-website/c_'+item+'.jpg'" lazy></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <pc-footer></pc-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      currentIndex:0,
      banner:[
          {id:1,image:'https://cdn.puditech.com/pd-website/version2/banner1.jpg',href:"/"},
          {id:2,image:'https://cdn.puditech.com/pd-website/version2/banner2.jpg',href:"/"},
          {id:3,image:'https://cdn.puditech.com/pd-website/version2/banner3.jpg',href:"/"},
          {id:4,image:'http://cdn.puditech.com/pd-website/banner_5.png',href:"/"},
      ],
      products:[
        {id:1, name:'power 7计时器PCBA', image:'http://cdn.puditech.com/pd-website/1-210G51012032Q.jpg'},
        {id:2, name:'水表PCBA', image:'http://cdn.puditech.com/pd-website/1-210G510121I64.jpg'},
        {id:3, name:'无人机控制板PCBA', image:'http://cdn.puditech.com/pd-website/1-210G51012315c.jpg'},
        {id:4, name:'汽车360全景系统主板PCBA', image:'http://cdn.puditech.com/pd-website/1-210G5101243134.jpg'},
        {id:5, name:'Slow Timer 计时器PCBA', image:'http://cdn.puditech.com/pd-website/1-210G5101305139.jpg'},
        {id:6, name:'潜航器电机驱动PCBA', image:'http://cdn.puditech.com/pd-website/1-210G5101143J8.jpg'},
        {id:7, name:'大灯控制器PCBA', image:'http://cdn.puditech.com/pd-website/1-210G510112I05.jpg'},
        {id:8, name:'雾化器PCBA', image:'http://cdn.puditech.com/pd-website/product1.jpg'},
      ],
      news:[
        {id:1,type:0,date:'2024-03',day:'04',title:'转型——在5G时代茁壮成长',content:"在2019年中国国际信息通信展览会上，随着工信部宣布：5G商用正式启动！5G时代正式进入了千万家百姓的生活中。中国移动，中国电信，中国联通也正式发布了各自的5G套餐。",},
        {id:2,type:1,date:'2024-03',day:'05',title:'PCBA工艺的应用范围及其组装时的考虑因素',content:'随着科技的发展，人们的生产技术也得到了提高，拥有了不少新的技术工艺。那么大家知道PCBA工艺是什么吗？它有哪些实际应用呢？我们在进行PCBA组装时又需要考虑哪些问题呢？下面就跟随小编一起看一下吧。以下关于“PCBA工艺的应用范围及其组装时的考虑因素”的介绍',}
      ],
      honors:[
        {id:1, name:'杭州市高新企业',image:'http://cdn.puditech.com/pd-website/gaoxinjishu.png'},
        {id:2, name:'未来科技城商会智能制造专委员会会员',image:'http://cdn.puditech.com/pd-website/weilaikeji.jpg'},
        {id:3, name:'出口资质证书',image:'http://cdn.puditech.com/pd-website/jinchukou.png'},
        {id:4, name:'中国制造网认证供应商',image:'http://cdn.puditech.com/pd-website/zhizaorenzheng .jpg'},
        {id:5, name:'IATF 16949质量管理体系证书',image:'http://cdn.puditech.com/pd-website/IATF16949.jpg'},
        {id:6, name:'ISO9001 质量管理体系证书',image:'http://cdn.puditech.com/pd-website/ISO9001.png'},
        {id:7, name:'ISO14001环境管理体系证书',image:'http://cdn.puditech.com/pd-website/ISO14001.jpg'},
        {id:8, name:'中国商业联合会会员',image:'http://cdn.puditech.com/pd-website/shanghui.jpg'},
      ],
      photo:[
        
      ]
    };
  },
  mounted() {
    
  },
  methods: {
    goProduct(value,key){
      if(key===0){
        this.$router.push({path:'/productCenter',query:{contActive:11}})
      }else if(key===1){
        this.$router.push({path:'/productCenter',query:{contActive:25}})
      }else if(key===2){
        this.$router.push({path:'/productCenter',query:{contActive:14}})
      }else if(key===3){
        this.$router.push({path:'/productCenter',query:{contActive:12}})
      }else if(key===4){
        this.$router.push({path:'/productCenter',query:{contActive:24}})
      }else if(key===5){
        this.$router.push({path:'/productCenter',query:{contActive:13}})
      }else if(key===6){
        this.$router.push({path:'/productCenter',query:{contActive:15}})
      }else if(key===7){
        this.$router.push({path:'/productCenter',query:{contActive:32}})
      }
    },
    goNews(key){
      console.log(key)
      if(key===0){
        this.$router.push({path:'/news',query:{contActive:1}})
      }else if(key===1){
        this.$router.push({path:'/news',query:{contActive:2}})
      }
    },
    showIndex(val){
      this.currentIndex=val
      // console.log(this.currentIndex)
    }
  }
};
</script>
<style lang="scss">
.home {
  margin: 0;
  padding: 0;
}

#memory-box{
  display:flex;
  justify-content: center;
  background-image: url(http://cdn.puditech.com/pd-website/black-back.png);
  perspective: 1000px;
  text-align: center;
  color: #fff;
  padding: 55px 0px 30px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1C1C1C;
  margin-top: 20px;

  .memory-header{
    width: 100%;
    text-align: center;
    h2{
      text-transform: uppercase;
      font-family: 'Times New Roman';
      font-size: 40px;
      margin: 0px;
      padding: 0px;
    }
    p{
      font-size: 24px;
      font-family: "Times New Roman";
    }
  }
  .memory-list{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .memory-row{
      margin-right: -15px;
      margin-left: -15px;
      .advantage_col{
        margin-top: 30px;
        margin-bottom: 50px;
        text-align: left;
        img{
          max-width: 100px;
          -webkit-transition: -webkit-transform 1.2s ease-out;
          vertical-align: middle;
          border: 0;
        }
        h4{
          text-align: left;
          padding-top: 8px;
          font-size: 18px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.1;
          color: inherit;
          a{
            color: #fff;
          }
        }
        span{
          display: inline-block;
          text-align: left;
          border-top: 1px solid #505050;
          padding-top: 9px;
          color: #fff;
        }
      }
    }
  }

  .col-md-9{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: 75%;
    float:left;
  }

  .col-md-6{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: 50%;
    float:left;
  }

  .col-md-3{
    width: 25%;
    float:left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

}

#about-box{
  width: 100%;
  display:inline-flex;
  justify-content: center;
  .company-about{
    box-sizing: border-box;
    padding:35px 20px 50px 20px;

    .about-title{
      box-sizing: border-box;
      border-bottom: 1px solid #eaeaea;
      text-align: left;
      h2{
        font-size: 24px;
        color: #767676;
        margin-bottom: -12px;
        font-family: 'Times New Roman';
      }
      span{ 
        font-size: 20px;
        color: #AD8645;
        padding-right: 7px;
        position: relative;
        top: 12px;
        background-color: #fff;
        font-family: 'Times New Roman';
      }
    }

    .about-content{
      box-sizing: border-box;
      p{
        line-height: 32px;
        margin: 0 0 10px;
        margin-top: 20px;
      }
      img{
        margin: 9px 30px 0px 0px;
        max-width: 360px;
        vertical-align: middle;
      }
      .btn{
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
      }
      a.btn-info{
        color: #000;
        background: transparent;
        border-radius: 0px;
        border: 1px solid #000;
        padding: 8px 36px;
      }
    }
  }
}

#honor-box{
  width: 100%;
  display:inline-flex;
  justify-content: center;
  margin-top: 45px;
  background-color: #F6F6F6;
  padding: 35px 0px 35px 0px;
  box-sizing: border-box;
  .honor-header{
    height: 65px;
    margin-bottom: 48px;
    h2{
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #38383A;
      margin: 0px;
      padding-top: 15px;
    }
    p{
      font-family: 'Times New Roman';
      text-align: center;
      padding-top: 2px;
      font-size: 16px;
      color: #3B3C3E;
      margin-bottom: 5px;
    }
  }

  .honor-list{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .honor{
      width: 25%;
      padding-left: 15px;
      padding-right: 15px;
      opacity: 1;
      transition: all 1s ease 0s, opacity 1.5s ease 0s;
      margin-bottom: 10px;
      .honor-title{
        text-align: center;
        padding: 0px 15px 0px 0px;
        height: 40px;
      }

      @media screen and (min-width: 320px) and (max-width: 768px){
        width: 50%;
      }
      @media screen and (max-width: 320px){
        min-height: 80px;
        margin-bottom: 10px;
        width: 100%;
      }
      @media (min-width: 769px){
        width: 33.33333333%;
      }

      @media (min-width: 992px){
        width: 25%;
      }

      

      &-image{
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 4px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        max-width: 100%;
        height:calc(100% - 70px);
        margin-bottom: 10px;
        // max-height: 320px;
        transition: all .2s ease-in-out;
      }
    }
  }
}

#news-box{
  width: 100%;
  display:inline-flex;
  justify-content: center;
  margin-top: 45px;
  .news-header{
    background: url(http://cdn.puditech.com/pd-website/line.gif) no-repeat center center;
    height: 65px;
    margin-bottom: 48px;
    h2{
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #38383A;
      margin: 0px;
      padding-top: 15px;
    }
    p{
      font-family: 'Times New Roman';
      text-align: center;
      padding-top: 2px;
      font-size: 16px;
      color: #3B3C3E;
      margin-bottom: 5px;
    }
  }
  .news-list{
    clear: both;
    list-style: none;
    margin: 0px;
    padding: 20px 0px 0px 15px;
    .news{
      margin-bottom: 20px;
      padding: 25px 15px;
      min-height: 70px;
      border: 1px solid #ccc;
      cursor: pointer;
      span{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        color: #fff;
        float: left;
        padding: 0px 2px;
        position: relative;
        left: -35px;
        top: -5px;
        strong{
          display: block;
          font-size: 24px;
          padding-top: 5px;
        }
        i{
          display: block;
          font-size: 12px;
          font-style: normal;
          font-family: Arial;
        }
      }
      .yellow_bg{
        background-color: #febf01;
      }
      .green_bg{
        background-color: #1BAA39;
      }
      .newTitle{
        color: #333;
        font-size: 16px;
        width: 85%;
        font-style: normal;
        display: inline-block;
        line-height: 18px;
        padding-top: 10px;
        margin-left: -20px;
      }
      .yellow_a:hover{
        color:#febf01;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
      }
      .green_a:hover{
        color:#1BAA39;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
      }
      em{
        width: 85%;
        font-style: normal;
        display: inline-block;
        line-height: 18px;
        color: #777;
        padding-top: 10px;
        margin-left: -20px;
        overflow: hidden; text-overflow:ellipsis; white-space: nowrap;
      }
    }
  }
}

#product-box{
  width: 100%;
  display:inline-flex;
  justify-content: center;
  margin-top: 45px;

  .product-header{
    background: url(http://cdn.puditech.com/pd-website/line.gif) no-repeat center center;
    height: 65px;
    margin-bottom: 48px;
    h2{
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #38383A;
      margin: 0px;
      padding-top: 15px;
    }
    p{
      font-family: 'Times New Roman';
      text-align: center;
      padding-top: 2px;
      font-size: 16px;
      color: #3B3C3E;
      margin-bottom: 5px;
    }
  }

  .product-list{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .product{
      width: 25%;
      padding-left: 15px;
      padding-right: 15px;
      min-height: 300px;
      opacity: 1;
      transition: all 1s ease 0s, opacity 1.5s ease 0s;
      margin-bottom: 10px;
      .product-title{
        text-align: center;
        padding: 0px 15px 0px 0px;
        height: 40px;
      }

      @media screen and (min-width: 320px) and (max-width: 768px){
        width: 50%;
      }
      @media screen and (max-width: 320px){
        min-height: 80px;
        margin-bottom: 10px;
        width: 100%;
      }
      @media (min-width: 769px){
        width: 33.33333333%;
      }

      @media (min-width: 992px){
        width: 25%;
      }

      

      &-image{
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 4px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
        max-height: 320px;
        transition: all .2s ease-in-out;
      }
    }
  }
}

#photo-box{
  width: 100%;
  display:inline-flex;
  justify-content: center;
  margin-top: 45px;
  background-color: #F6F6F6;
  padding: 35px 0px 35px 0px;
  box-sizing: border-box;
  .photo-header{
    height: 65px;
    margin-bottom: 48px;
    h2{
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #38383A;
      margin: 0px;
      padding-top: 15px;
    }
    p{
      font-family: 'Times New Roman';
      text-align: center;
      padding-top: 2px;
      font-size: 16px;
      color: #3B3C3E;
      margin-bottom: 5px;
    }
  }
  .photo-swaper{
    width: 100%;
  }
}

.photo{
  width: 100%;
  height:100%;
}

.module-header{
  width: 100%;
}

.container{
  @media (min-width: 769px){
    width: 750px;
  }
  @media (min-width: 992px){
    width: 970px;
  }
  @media (min-width: 1200px){
    width: 1170px;
  }
}

</style>
