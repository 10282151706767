<template>
    <div class="home" ref="boxView">
      <pc-header></pc-header>
      <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
      <div class="content">
        <img class="image" :src="image"/>
      </div>
      <pc-footer></pc-footer>
    </div>
  </template>
  <script>
  // @ is an alias to /src
  import PcHeader from "@/components/pc/header.vue"
  import PcNav from "@/components/pc/nav.vue"
  import PcFooter from "@/components/pc/footer.vue"
  export default {
    components: {PcHeader,PcNav,PcFooter},
    data() {
      return {
        image:"https://cdn.puditech.com/pd-website/version2/GD32F.jpg",
      }
    },
    mounted() {
    },
    methods: {
    }
  }
  </script>
  <style lang="scss" scoped>
  
  .home {
    margin: 0;
    padding: 0;
    .content{
      width: 100%;
      .image{
        width:60%;
        margin-left:20%;
      }
      .ee{
        width:50%;
        margin-left:25%;
      }
    }
  }
  </style>
  