<template>
  <div class="home" ref="boxView">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
      <div class="left">
        <div class="categories">
          <div class="title">关于谱地</div>
          <div class="nav-list" v-for="(v,k) in navList" :key="k" @click="goList(v)">>  {{v.name}}</div>
        </div>
      </div>
      <div class="right">
        <div class="list" v-if="contActive==1">
          <div class="title">公司介绍</div>
          <div class="detail">
            杭州谱地新能源科技有限公司始创于2010年，地处杭州未来科技城，业务辐射长三角地区，是一家集工业、汽车、消费等电子零部件制造与技术研发服务性企业，在嵌入式系统、物联网智能系统等具备技术开发前瞻性、量产制造专业性的项目组织和服务能力，多年的项目经验体现了我司在定制化、批量化产品项目中具有明显的竞争态势。
          </div>
          <div class="detail">
            公司拥有稳定的研发与生产制造管理团队，自设实验室和各类检测设备，在2019年公司荣获国家级高新企业、区级研发中心等荣誉，制造中心拥有中等规模的SMT、DIP以及成品组装产线，针对于中等批量的PCBA及成品制造有着较强的性价比优势。截止到2013年，公司多年稳定服务于通讯行业主板上市公司、家电行业龙头、大型国有出口贸易企业等几家优质客户，竭诚欢迎广大客户来电来函交流，希望我们的服务能为您带来满意的合作价值！
          </div>
          <div class="imgList">
            <div class="images" v-for="(value,key) in gsList" :key="key">
              <el-image class="image" :src="value" fit="contain"></el-image>
            </div>
          </div>
        </div>
        <div class="list" v-else-if="contActive==2">
          <div class="title">发展历程</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/fzlc.jpg" fit="contain" style="width:100%;margin-bottom:20px;"></el-image>
          <div class="detail">从零起家的微型加工企业发展成为一个活跃在工业与汽车 电子零部件领域极具创造力的供应商，这就是我们的历史。
          </div>
          <div class="detail">2010创办公司，面向电子零部件加工行业解决了企业自 我生存问题
          </div>
          <div class="detail">2011加工业务量翻翻的同时，论证企业未来发展模式与方向
          </div>
          <div class="detail">2012战略性明确了向汽车ADAS产业发展，当年开始实施 汽车360全景系统研发公司获得高新技术企业认定
          </div>
          <div class="detail">2013PCBA电子零部件制造转向中高端项目服务，打造先 进自动化产线
          </div>
          <div class="detail">2014启动汽车BLIS毫米波预警系统研发
          </div>
          <div class="detail">2015PCBA电子零部件制造服务首获世界500强ABB公司 项目调整细化了汽车底层控制零部件和无人驾驶核心毫米 波感测技术两大发展方向
          </div>
          <div class="detail">2016开始研发24GHZ预碰撞雷达系统启动汽车电装产品 系列的品牌建设
          </div>
          <div class="detail">2018车用预警系统实现知名品牌批量供货
          </div>
          <div class="detail">2020物联网系统研发和智能制造的配套优化
          </div>
          <div class="detail">
            2022已有多套物联网系统成品，并与多家企业达成合作
          </div>
          <div class="detail">
            2024发展无止境，与勤奋携手让业绩攀登
          </div>
        </div>
        <div class="list" v-else-if="contActive==3">
          <div class="title">经营理念</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/zyln.jpg" fit="contain" style="width:100%;margin-bottom:20px;"></el-image>
          <div class="detail">
            <div class="top">一、谱地科技的使命：</div> 
            <div class="bot">实现用户安全出行的智驾辅助科技产品专注带来价值，安全伴你行</div>  
          </div>
          <div class="detail">
            <div class="top">二、谱地科技的愿景：</div> 
            <div class="bot">打造企业一核双引擎：汽车电装核心技术，一流专家制造 团队，一流工业4.0智慧工厂</div>  
          </div>
          <div class="detail">
            <div class="top">三、谱地科技的理念：</div> 
            <div class="bot">勤业创能，敬业创道，务实创效，智慧创新，合作创赢； 科技兴司，基业常青</div>  
          </div>
          <div class="detail">
            <div class="top">成长型的企业管理模式</div> 
            <div class="bot">在与客户的合作过程中，我们认为重要的是：我们在决定为客户提供产品或服务之前会提出很多问题。 我们的一贯做法是，同客户共同探讨，从而根据客户需求提供适合的解决方案。在这里，我们更强调的是通过我们 的专业分析让客户了解自身需求的合理选择，为了达到最高的性价比——让我们从现在开始！</div>  
          </div>
          <div class="detail">
            <div class="top">创新</div> 
            <div class="bot">谱地科技集产品研发与生产于一身，不断追求一流的技术和制造水平，勇于开拓全新的生产工艺，因而员工的想象力得以充分发挥。凭借着公司多年积累下来的项目运作经验、 工艺技能以及专业的制造技术，我们完全有能力承接好客户的制造项目和提供可靠的产品。</div>  
          </div>
          <div class="detail">
            <div class="top">优化成本</div> 
            <div class="bot">在我们的整体价值链体系中，成本管理的目标在于确保始终能为客户提供一个最佳的成本平衡点，也就是说，无论生产批量的大小，均能以最经济最优化的成本结构组织生产。</div>  
          </div>
          <div class="detail">
            <div class="top">资源整合思维</div> 
            <div class="bot">在处理项目的过程中，除了发挥自身擅长之外，我们充分 调动企业周边良好合作关系的各种资源，以期更快更好的 服务于客户并满足客户的需求。</div>  
          </div>
          <div class="detail">
            <div class="top">质量与合作可靠性</div> 
            <div class="bot">客观的面对问题，务实且循序渐进，因此高质量是我们一 直以来追求并付诸行动的经营理念，也是我们追求完美的 具体体现。 我们向来是一个长期的、可靠的合作伙伴。 诚信经营，对我们来说至关重要，因而我们强调相互信任 和彼此坦诚，并以此作为促使企业关系良性发展的基础。</div>  
          </div>
        </div>
        <div class="list" v-else-if="contActive==4">
          <div class="title">管理政策</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/glzc.jpg" fit="contain" style="width:100%;margin-bottom:20px;"></el-image>
          <div class="detail">
            极具鲜明特征的管理模式是我们快速发展的先决条件
          </div>
          <div class="detail">
            谱地科技从初创到成熟这一特定的时期，需要对自我有限的资源进行合理配置，并谋求更多资源以期达到企业的成长，如何选择或设计企业的管理模式具有十分重要的意义。在此过程中与我们成长密切相关的所属区域、行业、 政策、人才、资本、与已经拥有的技术环境进行分析，逐步完善并显现我们特有的管理模式。
          </div>
          <div class="detail">
            <div class="top">优秀团队建设</div> 
            <div class="bot">高效、创新、高质量和可靠性是公司管理层的战略主导原 则，同时也是我们日常工作的指南，除主营业务外，我们 将同样的理念也推广到了员工管理和客户管理当中。</div>  
          </div>
          <div class="detail">
            <div class="top">人才吸纳建设</div> 
            <div class="bot">预谋业者，良才善用，能者居之；预谋职者，知人善用， 人尽其才；具有学习力、创新力及凝聚力的团队才能承担 企业发展的责任。</div>  
          </div>
          <div class="detail">
            <div class="top">客户服务政策</div> 
            <div class="bot">快速的响应解客户之急，细致的分析解客户之难，全力的 投入解客户之忧，与客户共进退，与客户共成长。</div>  
          </div>
          <div class="detail">
            <div class="top">技术发展政策</div> 
            <div class="bot">紧跟前沿技术发展，集中企业优势资源，全力拓展核心技 术区块，并将成果应用于产业产品与客户服务中。</div>  
          </div>
          <div class="detail">
            <div class="top">制造的标准化</div> 
            <div class="bot">在行业标准化框架下，通过分析产品的原理和特征，制定 科学的工艺与工程方法，以提高产品制造技术水平、质量 控制、生产秩序和经济效益。</div>  
          </div>
          <div class="detail">
            <div class="top">资源的集约化</div> 
            <div class="bot">一个高成长的企业，除了具备优秀团队、技术能力和制造 服务能力外，对上下游以及协作伙伴等资源的有效整合互 补是企业生命力、成长力不可或缺的条件。</div>  
          </div>
        </div>
        <div class="list" v-else-if="contActive==5">
          <div class="title">资质荣誉</div>
          <div class="detail fs20">
           我们每天都在努力，让今天好的状态变得更好。如果我们 所取得的成绩能够赢得客户的赞誉，这不仅是对我们工作 的认可，同时更是对我们的鞭策。
          </div>
          <div class="detail-list">
            <div class="honor" v-for="(value,key) in honors" :key="key">
              
              <el-image class="honor-image" :src="value.image" fit="contain"></el-image>
              <p class="honor-title">{{value.name}}</p>
            </div>
          </div>
        </div>

        <div class="list" v-if="contActive==5">
          <div class="title2">软著证书</div>
          <div class="detail-list" style="margin-top:0;">
            <div class="honor" v-for="(value,key) in rzList" :key="key">
              <el-image class="honor-image" :src="value" fit="contain"></el-image>
            </div>
          </div>
          <div class="title2">实用新型专利证书</div>
          <div class="detail-list" style="margin-top:0;">
            <div class="honor" v-for="(value,key) in zlList" :key="key">
              <el-image class="honor-image" :src="value" fit="contain"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>
<script>
// @ is an alias to /src

import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      navList:[
        {id:1,name:"公司介绍"},
        {id:2,name:"发展历程"},
        {id:3,name:"经营理念"},
        {id:4,name:"管理政策"},
        {id:5,name:"资质荣誉"},
      ],
      contActive:1,
      currentIndex:1,
      rzList:[
      'http://cdn.puditech.com/pd-website/honor/rz1.jpg','http://cdn.puditech.com/pd-website/honor/rz2.jpg','http://cdn.puditech.com/pd-website/honor/rz3.jpg','http://cdn.puditech.com/pd-website/honor/rz4.jpg'
      ],
      zlList:[
      'http://cdn.puditech.com/pd-website/honor/zl1.jpg','http://cdn.puditech.com/pd-website/honor/zl2.jpg','http://cdn.puditech.com/pd-website/honor/zl3.jpg','http://cdn.puditech.com/pd-website/honor/zl4.png'
      ],
      honors:[
        {id:1, name:'企业营业执照',image:'http://cdn.puditech.com/pd-website/honor/yingyezhizhao.jpg'},
        {id:2, name:'出口企业证书',image:'http://cdn.puditech.com/pd-website/honor/chukou.jpg'},
        {id:9, name:'国家高新证书',image:'http://cdn.puditech.com/pd-website/honor/gaoxinzhengshu.jpg'},
        {id:10, name:'杭州市余杭区研发中心',image:'http://cdn.puditech.com/pd-website/honor/yanfazhongxin.jpg'},
        {id:11, name:'杭州市高新企业',image:'http://cdn.puditech.com/pd-website/honor/gaoxinqiye.jpg'},
        {id:14, name:'中国制造网认证供应商',image:'http://cdn.puditech.com/pd-website/honor/zhizhao.jpg'},
        {id:3, name:'IATF 16949质量管理证书',image:'http://cdn.puditech.com/pd-website/honor/ITAF.jpg'},
        {id:4, name:'ISO9001 质量管理证书',image:'http://cdn.puditech.com/pd-website/honor/ISO9001.jpg'},
        {id:5, name:'ISO14001环境管理证书',image:'http://cdn.puditech.com/pd-website/honor/ISO14001.jpg'},
        {id:6, name:'安全生产标准化证书',image:'http://cdn.puditech.com/pd-website/honor/anquanshengchan.jpg'},
        {id:13, name:'科技型中小企业',image:'https://cdn.puditech.com/pd-website/version2/kjzs.png'},
        {id:12, name:'未来科技城商会智能制造专委员会会员',image:'http://cdn.puditech.com/pd-website/honor/weilai.jpg'},
      ],
      gsList:[
      'https://cdn.puditech.com/pd-website/version2/gsjs1.jpg','https://cdn.puditech.com/pd-website/version2/gsjs3.jpg'
      ],
    }
  },
  mounted() {

  },
  methods: {
    goList(e){
      // console.log(e)
      this.contActive=e.id
    },
    showActive(val){
      this.contActive=val
      // console.log(this.contActive)
    },
    showIndex(val){
      this.currentIndex=val
      // console.log(this.currentIndex)
    }
  }
}
</script>
<style lang="scss" scoped>

.home {
  margin: 0;
  padding: 0;
  .content{
    width: 100%;
    display:flex;
    justify-content: center;
    padding:50px 0;
    margin:0 auto;
    .left{
      width:30%;
      .title{
        height:60px;
        line-height:60px;
        border-bottom: 1px solid #eee;
        font-size:30px;
      }
      .categories{
        width:200px;
        .nav-list{
          height:40px;
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
      }
    }
    .right{
      padding:0 50px;
      .list{
        .title{
          height:60px;
          line-height:60px;
          font-size:30px;
        }
        .title2{
          height:60px;
          line-height:60px;
          font-size:20px;
          margin-top: 20px;
        }
        .detail{
          line-height:25px;
          margin-bottom:10px;
          .top{
            font-size:20px;
            line-height:40px;
          }
          .bot{
            padding-left:30px;
          }
        }
        .detail-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 20px;
          .honor{
            margin-right:10px;
            margin-top:20px;
            width:200px;
            .honor-image{
              width:150px;
             height:150px;
             margin-left:25px;
            }
            .honor-title{
            width:200px;
            height:30px;
            line-height:30px;
            margin-top:20px;
            text-align: center;
          }
          }
        }
        .imgList{
          display: flex;
          justify-content: space-around;
          .images{
            width:45%;
            .image{
              height:100%;
            }
          }
        }
      }
    }
  }
  .fs20{
    font-size: 20px;
          margin-top: 20px;
  }
  .content{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
  .right{
    @media (min-width: 769px){
      min-height: 370px;
    }
    @media (min-width: 992px){
      min-height:420px;
    }
    @media (min-width: 1200px){
      min-height: 470px;
    }
  }
}
</style>
