<template>
  <div class="home" ref="boxView">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
      <div class="left">
        <div class="categories">
          <div class="title">核心竞争力</div>
          <div class="nav-list" v-for="(v,k) in navList" :key="k" @click="goList(v)">>  {{v.name}}</div>
        </div>
      </div>
      <div class="right">
        <div class="list" v-if="contActive==1">
          <div class="title">产品研发</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/cpyf.jpg" fit="contain" style="width:100%;"></el-image>
          <div class="detail fs20">
            领先的技术策略和贯穿于全环节的设计理念、
          </div>
          <div class="detail">
            创新——借助成熟技术原理，在需求的推动下通过高效的技术整合重新定义并升级产品指标，开辟新的应用领域。所有这些都要求我们提前介入客户的产品形成过程，其结果可能是几个竭尽技术可行性的非常规的解决方案。
          </div>
          <div class="detail fs20">
           完美产品的研发路线
          </div>
          <div class="product" v-for="(v,k) in productList" :key="k">
           <div class="fs18 name" @click="proSub(k)"><span v-show="proShow===k">-</span><span v-show="proShow!=k">+</span>  {{v.name}}</div>
           <div class="product-list" v-show="proShow===k">
            {{v.content}}
            <div style="font-weight:600;margin-top:10px;">{{v.tips}}</div>
           </div>
          </div>
        </div>
        <div class="list" v-else-if="contActive==2">
          <div class="title">生产工艺</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/scgy.jpg" fit="contain" style="width:100%;"></el-image>
          <div class="detail fs20">
            我们的制造专长就是对一个零部件内各种复杂电子器件和PCB结合成一体
          </div>
          <div class="detail">
            在一个错综复杂的生产流程内，所有的生产步骤和工艺均安排得井井有条，相互协调的分毫不差。然而，使得不同的材料、不同的焊接方法和不同的加工工艺完美地结合并使之正常工作却是我公司很多年生产经验的结晶，除了制造本身，我们更需要通读所有的项目信息、软硬件制定、初期实验等，每一个产品或部件制造之前都需要海量的前期工作，这是我们最有价值的部分。
          </div>
          <div class="product" v-for="(v,k) in workmanship" :key="k">
           <div class="fs18 name" @click="proSub(k)"><span v-show="proShow===k">-</span><span v-show="proShow!=k">+</span>  {{v.name}}</div>
           <div class="product-list" v-show="proShow===k">
            {{v.content}}
            <div style="font-weight:600;margin-top:10px;">{{v.tips}}</div>
           </div>
          </div>
        </div>
        <div class="list" v-else-if="contActive==3">
          <div class="title">创新实践</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/cxsj.jpg" fit="contain" style="width:100%;"></el-image>
          <div class="detail fs20">
            与专业供应商优势互补，使我们制造过程获得更多创新方法
          </div>
          <div class="detail">
            专业且信誉优良的供应商是我们的核心资源。对于很多特定用途或特定要求的OEM项目，在制定它的制造流程及工艺过程中，专业供应商深入的配合与辅助使我们的技术人员对产品制造能够全面科学分析解读，更有创造力的面向每个环节。比如产品BOM的分析及器件同等替代、结构件的材料选择及制造工艺优化、不同工序的对接安排以及多重测试环节的选择等。
          </div>
          <div class="detail fs20">
            与高校和研究机构合作资源整合，使我们产品创新更有活力
          </div>
          <div class="detail">
            谱地科技是客户的合作伙伴。我们在按照客户要求进行服务的同时，提供创新性的制造工艺或实现新的附加功能、更好的产品外观设计或降低零部件成本的新方法。然而，我们的员工并不是在拿自己的创意和知识下赌注。对我们来说，与外部的研究机构和高校合作也是一种创新。经过和客户协商并结合自己的考量，我们会确定一个和外部合作伙伴合作的研发项目。来自我公司技术人员和工程师的基础知识和高校的方法论将在这个项目的执行过程中不断交汇融合。
          </div>
          <div class="product" v-for="(v,k) in innovate" :key="k">
           <div class="fs18 name" @click="proSub(k)"><span v-show="proShow===k">-</span><span v-show="proShow!=k">+</span>  {{v.name}}</div>
           <div class="product-list" v-show="proShow===k">
            {{v.content}}
            <div style="font-weight:600;margin-top:10px;">{{v.tips}}</div>
           </div>
          </div>
        </div>
        <div class="list" v-else-if="contActive==4">
          <div class="title">项目管理</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/xmgl.jpg" fit="contain" style="width:100%;"></el-image>
          <div class="detail fs20">
            高质量的产品源于专业高效的项目管理
          </div>
          <div class="detail">
            在项目导入时，公司会确定具体的项目负责人，同时其与公司内部各部门频繁接触，汇总制定项目验收标准，在整个过程中项目负责人会保持与客户紧密合作，明确常态性的和各个阶段衔接期的项目管理方面的具体工作和进度情况，并逐步加以补充、追踪和完善落实。
          </div>
          <div class="product" v-for="(v,k) in project" :key="k">
           <div class="fs18 name" @click="proSub(k)"><span v-show="proShow===k">-</span><span v-show="proShow!=k">+</span>  {{v.name}}</div>
           <div class="product-list" v-show="proShow===k">
            {{v.content}}
            <div style="font-weight:600;margin-top:10px;">{{v.tips}}</div>
           </div>
          </div>
        </div>
        <div class="list" v-else-if="contActive==5">
          <div class="title">质量认证</div>
          <el-image class="image" src="https://cdn.puditech.com/pd-website/version2/zlrz.jpg" fit="contain" style="width:100%;"></el-image>
          <div class="detail fs20">
            质量管理体系认证
          </div>
          <div class="detail">
            我们目前执行的是一个专门针对汽车行业实际情况而制定的质量管理体系，并通过了认证。因此，我们执行的是国际汽车标准。
          </div>
          <div class="product" v-for="(v,k) in quality" :key="k">
           <div class="fs18 name" @click="proSub(k)"><span v-show="proShow===k">-</span><span v-show="proShow!=k">+</span>  {{v.name}}</div>
           <div class="product-list" v-show="proShow===k">
            {{v.content}}
            <div style="font-weight:600;margin-top:10px;">{{v.tips}}</div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>
<script>
// @ is an alias to /src

import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
  name: "Home",
  components: {PcHeader,PcNav,PcFooter},
  data() {
    return {
      navList:[
        {id:1,name:"产品研发"},
        {id:2,name:"生产工艺"},
        {id:3,name:"创新实践"},
        {id:4,name:"项目管理"},
        {id:5,name:"质量认证"},
      ],
      contActive:1,
      currentIndex:2,
      productList:[
        {
            name:"需求的分析过程",
            content:"在此过程，我们将通过征询的方式了解项目的应用背景、周期计划、性价比定位、研发范围以及客户的关键原则倾向，项目小组根据这些信息进行可行性论证并将结果与客户沟通、交流、确认，在项目正式启动前与客户之间形成一种共识，并正式达成框架合作协议"
        },
        {
            name:"方案的制定阶段",
            content:"在方案制定阶段，我们会与客户之间开展进一步深入的细节交流。需要讨论的问题将集中在现有哪些可行的技术路线？每种技术路线会有哪些工艺以及成本要求？对于结构部分哪种注塑工艺最合适？还有其他哪种特殊材料工艺？哪些结合技术适合于该产品？提出这些问题并不重要，重要的是经过讨论后得出的结论。因为我们要在这一早期方案制定阶段就确定最有效的制造方式及最终的目标产品。",
            tips:"我们的目标是：性价比与可靠性的合理配置。"
        },
        {
            name:"研发的开展阶段",
            content:"经过一次预研得出初步结果后，在检验最终设计方案、生产工艺流程以及产品规格清单中明确了所有的细节参数的情况下，才开始进入真正的产品研发。在此期间，我们为研发人员和项目主管人员提供专业的工作场地，配备有仿真系统、必备的相关仪器设备、不同的设计软件。在整个研发阶段，相关人员可以随时互动相关技术数据，快速高效的推进整个项目。",
        },
        {
            name:"产品的终试阶段",
            content:"在取得第一次正式的样品之后，我们将对其进行全方位测试，比如功能运行、耐冷耐热、振动、电磁干扰等，以便为后续的技术修正提供依据。我们的工程师将对试验结果进行分析和评估，然后从中确定修正方案，并将其准确地记录在质量管理系统内。",
        },
        {
            name:"生产的准备阶段",
            content:"当产品研发进入终试阶段时，生产的技术部门和采购部门需编制产品规格清单，以便对所需设备、工艺流程、专用工装、检测程序以及其它生产资料的及时采购和建造。这些自制或外购的设备和工装模具经过几个阶段的修改完善，在满足了准批量产品的所有要求后予以批准放行。接下来将要向客户转交生产件批准程序文件（PPAP）。",
        },
        {
            name:"生产的启动阶段",
            content:"批量生产我们会和客户一起启动。届时，首先要通过客户审核(Audit）。在这个程序中精确定义了所要检查的项目，只有这些检查项目达标后才会对用于批量生产的制造流程和产品予以批准放行。与此同时，持续改进程序（KVP）开始执行。每一个步骤都将记录在册，并具备可追溯性。由此可以看出，从一开始就以高标准化的展开工作并非徒劳，同时也证实了整个公司的能力和所制定的工艺流程的效率。",
        }
      ],
      workmanship:[
        {
            name:"新技术，企业自我革新并不断成长的方向标",
            content:"开创新的技术产品并应用于现实市场，是谱地科技持续成长的新动力。通过我们持续不断的研究与投入，高性价比且完全适应需求的技术产品为客户带来最具价值的回报。"
        },
        {
            name:"我们的擅长在于运用综合能力达成项目要求",
            content:"仅仅掌握单一的技术能力已经远远不能满足客户的需求。客户对质量、工艺的特殊要求一定要通过不同技术的整体融合才能得以实现。",
            tips:"我们的目标是：可靠性。因为选择任何特定的技术及制造工艺都要经受得起可持续性的考验。",
        },
      ],
      innovate:[
        {
            name:"首次即完美实现客户交货要求的OEM项目",
            content:"我们有很多的案例，在产品复杂与客户严苛的情况下，首次订单就实现了100%的交货合格率，并且是高性价比的，因为我们降低了客户的采购成本。典型项目之一，我们为ABB公司服务的一款安防产品，解决了冷门器件的渠道及采购成本，高成本且非常规松下开关器件的国产化替代等。这不是一开始我们能够做到的，因为在此过程中我们运用了历史经验以及关键供应商的积极配合。"
        },
        {
            name:"共同开发的项目",
            content:"比较重要的项目课题往往涉及到核心算法相关的问题，比如基于DSP处理器硬件平台的摄像头视觉融合技术，用于汽车360度全景视觉驻车系统的图形融合算法。到目前为止，我们和中国科学院微电子所车联网研究中心的合作非常成功，我们将在未来继续延续这样的成功案例。",
        },
      ],
      project:[
        {
            name:"自项目启动就由可信赖的人管理",
            content:"我们的客户就是最可信赖的项目管理人选。从项目开始客户在可接受的专业范围内了解并参与其中，对整个研发阶段和生产阶段的最新信息了如指掌。"
        },
        {
            name:"项目负责人就是代表客户的联系人",
            content:"项目负责人将对从研发到批量生产的整个过程进行跟踪管理。他将对项目内部的会议内容以及接待客户的会谈内容作详细记录。项目负责人为专职人员，他将是每个项目阶段指定的客户联系人。",
        },
      ],
      quality:[
        {
            name:"质量规划",
            content:"我们的质量规划是在对可行性分析报告概况总结的基础上制定出来的。经过对质量保证方案的整理加工，借助于EDV-Tools（CAQ）的数据评估结果，最后要对产品研发以及失效模式及影响分析（FMEA）的每一个阶段编制一个质量控制计划。"
        },
        {
            name:"首批样件制定",
            content:"我们对产品的质量和可靠性是以齐全的、现代化的检测设备加以保障的。届时，我们将根据客户的特殊要求做大量的实验，并按标准流程将结果传输到首批样件检验报告（EMPB-VDA）和生产件批准程序（PPAP-QS-9000）的系统文件中。其结果数据将自动进行评估，为今后执行项目提供保障。",
        },
        {
            name:"外购件采购计划",
            content:"为了保证外购件的质量，还需要从我们的'三立柱模型'说起——我们要求供应商首先要按照汽车专用装备建立符合DIN ISO EN 9000 ff.的质量管理体系（QMS）；其次要签署一个经过认可的总体和零部件专项质量管理协议（QMV）；最后要使用外购件质量管理文件（QMZ）。"
        },
        {
            name:"生产流程监控",
            content:"我们引入了最新的流程内部检测技术，以实现对生产流程的持续监控。这样我们就可以针对各个加工工序的不同做出适当的调整，从而有效地保障许多单个生产流程的高质量标准。",
        },
      ],
      proShow:0,
    }
  },
  mounted() {
  },
  methods: {
    goList(e){
      // console.log(e)
      this.contActive=e.id
    },
    showActive(val){
      this.contActive=val
      // console.log(this.contActive)
    },
    showIndex(val){
      this.currentIndex=val
      console.log(this.currentIndex)
    },
    proSub(e){
        this.proShow=e
    }
  }
}
</script>
<style lang="scss" scoped>

.home {
  margin: 0;
  padding: 0;
  .content{
    width: 100%;
    display:flex;
    justify-content: center;
    padding:50px 0;
    margin:0 auto;
    .left{
      width:30%;
      .title{
        height:60px;
        line-height:60px;
        border-bottom: 1px solid #eee;
        font-size:30px;
      }
      .categories{
        width:200px;
        .nav-list{
          height:40px;
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
      }
    }
    .right{
      padding:0 50px;
      .list{
        .title{
          height:60px;
          line-height:60px;
          font-size:30px;
        }
        .detail{
          line-height:25px;
          margin-bottom:10px;
          .top{
            font-size:20px;
            line-height:40px;
          }
          .bot{
            padding-left:30px;
          }
        }
        .detail-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 20px;
          div{
            width:200px;
            height:30px;
            line-height:30px;
          }
        }
        .product{
          line-height:25px;
          margin-bottom:10px;
          .product-list{
            border-radius: 10px;
            background-color: #eee;
            padding:20px 15px;
            .name{
                cursor: pointer;
            }
          }
        }
      }
    }
  }
  .fs20{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .fs18{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .content{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
  .right{
    @media (min-width: 769px){
      min-height: 370px;
    }
    @media (min-width: 992px){
      min-height:420px;
    }
    @media (min-width: 1200px){
      min-height: 470px;
    }
  }
}
</style>
