<template>
    <div class="home" ref="boxView" id="top">
      <pc-header></pc-header>
      <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
      <div class="box">
        <div class="left">
          <div class="title">类别</div>
          <div class="list" v-for="(v,k) in list3" :key="k" @click="goElectric(v,k)">
              <div class="name">{{v.name}}</div>
          </div>
        </div>
        <div class="right">
          <div class="title">产品详情介绍</div>
          <div class="content" v-if="index===0">
            <div class="back" @click="back">返回</div>
            <img class="image" :src="image1"/>
            <img class="image" :src="image2"/>
            <img class="image" :src="image3"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：充电宝多功能输出解决方案</div>
              <div class="next" @click="next">下一篇：如何选购产品</div>
            </div>
          </div>
          <div class="content" v-if="index===1">
            <div class="back" @click="back">返回</div>
            <img class="image" :src="image4"/>
            <img class="image" :src="image5"/>
            <img class="image" :src="image6"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：什么是无电电器</div>
              <div class="next" @click="next">下一篇：探寻电源平台性能</div>
            </div>
          </div>
          <div class="content" v-if="index===2">
            <div class="back" @click="back">返回</div>
            <img class="image" :src="image7"/>
            <img class="image" :src="image8"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：如何选购产品</div>
              <div class="next" @click="next">下一篇：如何实现应用创新</div>
            </div>
          </div>
          <div class="content" v-if="index===3">
            <div class="back" @click="back">返回</div>
            <img class="image" :src="image9"/>
            <img class="image" :src="image10"/>
            <img class="image" :src="image11"/>
            <img class="image" :src="image12"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：探寻电源平台性能</div>
              <div class="next" @click="next">下一篇：EE充电头</div>
            </div>
          </div>
          <div class="content" v-if="index===4">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="ee1"/>
            <img class="ee" :src="ee2"/>
            <img class="ee" :src="ee3"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：如何实现应用创新</div>
              <div class="next" @click="next">下一篇：无电7MM热胶枪</div>
            </div>
          </div>
          <div class="content" v-if="index===5">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="five1"/>
            <img class="ee" :src="five2"/>
            <img class="ee" :src="five3"/>
            <img class="ee" :src="five4"/>
            <img class="ee" :src="five5"/>
            <img class="ee" :src="five6"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：EE充电头</div>
              <div class="next" @click="next">下一篇：无电补水彩妆镜</div>
            </div>
          </div>
          <div class="content" v-if="index===6">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="six1"/>
            <img class="ee" :src="six2"/>
            <img class="ee" :src="six3"/>
            <img class="ee" :src="six4"/>
            <img class="ee" :src="six5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电7MM热胶枪</div>
              <div class="next" @click="next">下一篇：无电弹匣研磨器</div>
            </div>
          </div>
          <div class="content" v-if="index===7">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="seven1"/>
            <img class="ee" :src="seven2"/>
            <img class="ee" :src="seven3"/>
            <img class="ee" :src="seven4"/>
            <img class="ee" :src="seven5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电补水彩妆镜</div>
              <div class="next" @click="next">下一篇：无电定型梳</div>
            </div>
          </div>
          <div class="content" v-if="index===8">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="eight1"/>
            <img class="ee" :src="eight2"/>
            <img class="ee" :src="eight3"/>
            <img class="ee" :src="eight4"/>
            <img class="ee" :src="eight5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电弹匣研磨器</div>
              <div class="next" @click="next">下一篇：无电卷发棒</div>
            </div>
          </div>
          <div class="content" v-if="index===9">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="nine1"/>
            <img class="ee" :src="nine2"/>
            <img class="ee" :src="nine3"/>
            <img class="ee" :src="nine4"/>
            <img class="ee" :src="nine5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电定型梳</div>
              <div class="next" @click="next">下一篇：无电扩音趣玩音箱</div>
            </div>
          </div>
          <div class="content" v-if="index===10">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="ten1"/>
            <img class="ee" :src="ten2"/>
            <img class="ee" :src="ten3"/>
            <img class="ee" :src="ten4"/>
            <img class="ee" :src="ten5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电卷发棒</div>
              <div class="next" @click="next">下一篇：无电美发夹</div>
            </div>
          </div>
          <div class="content" v-if="index===11">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="hair1"/>
            <img class="ee" :src="hair2"/>
            <img class="ee" :src="hair3"/>
            <img class="ee" :src="hair4"/>
            <img class="ee" :src="hair5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电扩音趣玩音箱</div>
              <div class="next" @click="next">下一篇：无电巧妍美眼仪</div>
            </div>
          </div>
          <div class="content" v-if="index===12">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="eye1"/>
            <img class="ee" :src="eye2"/>
            <img class="ee" :src="eye3"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电美发夹</div>
              <div class="next" @click="next">下一篇：无电双用双温探针</div>
            </div>
          </div>
          <div class="content" v-if="index===13">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="probe1"/>
            <img class="ee" :src="probe2"/>
            <img class="ee" :src="probe3"/>
            <img class="ee" :src="probe4"/>
            <img class="ee" :src="probe5"/>
            <img class="ee" :src="probe6"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电巧妍美眼仪</div>
              <div class="next" @click="next">下一篇：无电细雾喷枪</div>
            </div>
          </div>
          <div class="content" v-if="index===14">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="mist1"/>
            <img class="ee" :src="mist2"/>
            <img class="ee" :src="mist3"/>
            <img class="ee" :src="mist4"/>
            <img class="ee" :src="mist5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电双用双温探针</div>
              <div class="next" @click="next">下一篇：无电悦动幻彩灯</div>
            </div>
          </div>
          <div class="content" v-if="index===15">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="lamp1"/>
            <img class="ee" :src="lamp2"/>
            <img class="ee" :src="lamp3"/>
            <img class="ee" :src="lamp4"/>
            <img class="ee" :src="lamp5"/>
            <img class="ee" :src="lamp6"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电细雾喷枪</div>
              <div class="next" @click="next">下一篇：无电自动电烙铁</div>
            </div>
          </div>
          <div class="content" v-if="index===16">
            <div class="back" @click="back">返回</div>
            <img class="ee" :src="iron1"/>
            <img class="ee" :src="iron2"/>
            <img class="ee" :src="iron3"/>
            <img class="ee" :src="iron4"/>
            <img class="ee" :src="iron5"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电悦动幻彩灯</div>
              <div class="next" @click="next">下一篇：车载杀菌宝</div>
            </div>
          </div>
          <div class="content" v-if="index===17">
            <div class="back" @click="back2">返回</div>
            <img class="cz" :src="car1"/>
            <img class="cz" :src="car2"/>
            <img class="cz" :src="car3"/>
            <img class="cz" :src="car4"/>
            <img class="cz" :src="car5"/>
            <img class="cz" :src="car6"/>
            <img class="cz" :src="car7"/>
            <img class="cz" :src="car8"/>
            <img class="cz" :src="car9"/>
            <img class="cz" :src="car10"/>
            <img class="cz" :src="car11"/>
            <img class="cz" :src="car12"/>
            <img class="cz" :src="car13"/>
            <img class="cz" :src="car14"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：无电自动电烙铁</div>
              <div class="next" @click="next">下一篇：家用杀菌宝</div>
            </div>
          </div>
          <div class="content" v-if="index===18">
            <div class="back" @click="back2">返回</div>
            <img class="cz" :src="home1"/>
            <img class="cz" :src="home2"/>
            <img class="cz" :src="home3"/>
            <img class="cz" :src="home4"/>
            <img class="cz" :src="home5"/>
            <img class="cz" :src="home6"/>
            <img class="cz" :src="home7"/>
            <img class="cz" :src="home8"/>
            <img class="cz" :src="home9"/>
            <img class="cz" :src="home10"/>
            <img class="cz" :src="home11"/>
            <img class="cz" :src="home12"/>
            <img class="cz" :src="home13"/>
            <img class="cz" :src="home14"/>
            <img class="cz" :src="home15"/>
            <div class="page">
              <div class="pre" @click="pre">上一篇：车载杀菌宝</div>
              <div class="next" @click="next">下一篇：没有了</div>
            </div>
          </div>
        </div>
      </div>
      <pc-footer></pc-footer>
    </div>
  </template>
  <script>
  // @ is an alias to /src
  import PcHeader from "@/components/pc/header.vue"
  import PcNav from "@/components/pc/nav.vue"
  import PcFooter from "@/components/pc/footer.vue"
  export default {
    components: {PcHeader,PcNav,PcFooter},
    data() {
      return {
        list3:[
          {
              name:"1、什么是无电电器,点击了解"
          },
          {
              name:"2、如何选购产品,点击了解"
          },
          {
              name:"3、探寻电源平台性能,点击了解"
          },
          {
              name:"4、如何实现应用创新,点击了解"
          },
          {
              name:"EE充电头",
          },
          {
              name:"无电7MM热胶枪"
          },
          {
              name:"无电补水彩妆镜"
          },
          {
              name:"无电弹匣研磨器"
          },
          {
              name:"无电定型梳"
          },
          {
              name:"无电卷发棒"
          },
          {
              name:"无电扩音趣玩音箱"
          },
          {
              name:"无电美发夹"
          },
          {
              name:"无电巧妍美眼仪"
          },
          {
              name:"无电双用双温探针"
          },
          {
              name:"无电细雾喷枪"
          },
          {
              name:"无电悦动幻彩灯"
          },
          {
              name:"无电自动电烙铁"
          },
          {
              name:"车载杀菌宝"
          },
          {
              name:"家用杀菌宝"
          },
        ],
        index:0,
        contActive:1,
        currentIndex:3,
        image1:require('@/assets/electric/one/1.jpg'),
        image2:require('@/assets/electric/one/2.jpg'),
        image3:require('@/assets/electric/one/3.jpg'),
        image4:require('@/assets/electric/two/1.jpg'),
        image5:require('@/assets/electric/two/2.jpg'),
        image6:require('@/assets/electric/two/3.jpg'),
        image7:require('@/assets/electric/three/1.jpg'),
        image8:require('@/assets/electric/three/2.jpg'),
        image9:require('@/assets/electric/four/1.jpg'),
        image10:require('@/assets/electric/four/2.jpg'),
        image11:require('@/assets/electric/four/3.jpg'),
        ee1:require('@/assets/electric/EE/1.jpg'),
        ee2:require('@/assets/electric/EE/2.jpg'),
        ee3:require('@/assets/electric/EE/3.jpg'),
        five1:require('@/assets/electric/five/1.jpg'),
        five2:require('@/assets/electric/five/2.jpg'),
        five3:require('@/assets/electric/five/3.jpg'),
        five4:require('@/assets/electric/five/4.jpg'),
        five5:require('@/assets/electric/five/5.jpg'),
        five6:require('@/assets/electric/five/6.jpg'),
        six1:require('@/assets/electric/six/1.jpg'),
        six2:require('@/assets/electric/six/2.jpg'),
        six3:require('@/assets/electric/six/3.jpg'),
        six4:require('@/assets/electric/six/4.jpg'),
        six5:require('@/assets/electric/six/5.jpg'),
        seven1:require('@/assets/electric/seven/1.jpg'),
        seven2:require('@/assets/electric/seven/2.jpg'),
        seven3:require('@/assets/electric/seven/3.jpg'),
        seven4:require('@/assets/electric/seven/4.jpg'),
        seven5:require('@/assets/electric/seven/5.jpg'),
        seven6:require('@/assets/electric/seven/5.jpg'),
        eight1:require('@/assets/electric/eight/1.jpg'),
        eight2:require('@/assets/electric/eight/2.jpg'),
        eight3:require('@/assets/electric/eight/3.jpg'),
        eight4:require('@/assets/electric/eight/4.jpg'),
        eight5:require('@/assets/electric/eight/5.jpg'),
        nine1:require('@/assets/electric/nine/1.jpg'),
        nine2:require('@/assets/electric/nine/2.jpg'),
        nine3:require('@/assets/electric/nine/3.jpg'),
        nine4:require('@/assets/electric/nine/4.jpg'),
        nine5:require('@/assets/electric/nine/5.jpg'),
        ten1:require('@/assets/electric/ten/1.jpg'),
        ten2:require('@/assets/electric/ten/2.jpg'),
        ten3:require('@/assets/electric/ten/3.jpg'),
        ten4:require('@/assets/electric/ten/4.jpg'),
        ten5:require('@/assets/electric/ten/5.jpg'),
        hair1:require('@/assets/electric/hair/1.jpg'),
        hair2:require('@/assets/electric/hair/2.jpg'),
        hair3:require('@/assets/electric/hair/3.jpg'),
        hair4:require('@/assets/electric/hair/4.jpg'),
        hair5:require('@/assets/electric/hair/5.jpg'),
        eye1:require('@/assets/electric/eye/1.jpg'),
        eye2:require('@/assets/electric/eye/2.jpg'),
        eye3:require('@/assets/electric/eye/3.jpg'),
        probe1:require('@/assets/electric/probe/1.jpg'),
        probe2:require('@/assets/electric/probe/2.jpg'),
        probe3:require('@/assets/electric/probe/3.jpg'),
        probe4:require('@/assets/electric/probe/4.jpg'),
        probe5:require('@/assets/electric/probe/5.jpg'),
        probe6:require('@/assets/electric/probe/6.jpg'),
        mist1:require('@/assets/electric/mist/1.jpg'),
        mist2:require('@/assets/electric/mist/2.jpg'),
        mist3:require('@/assets/electric/mist/3.jpg'),
        mist4:require('@/assets/electric/mist/4.jpg'),
        mist5:require('@/assets/electric/mist/5.jpg'),
        lamp1:require('@/assets/electric/lamp/1.jpg'),
        lamp2:require('@/assets/electric/lamp/2.jpg'),
        lamp3:require('@/assets/electric/lamp/3.jpg'),
        lamp4:require('@/assets/electric/lamp/4.jpg'),
        lamp5:require('@/assets/electric/lamp/5.jpg'),
        lamp6:require('@/assets/electric/lamp/6.jpg'),
        iron1:require('@/assets/electric/iron/1.jpg'),
        iron2:require('@/assets/electric/iron/2.jpg'),
        iron3:require('@/assets/electric/iron/3.jpg'),
        iron4:require('@/assets/electric/iron/4.jpg'),
        iron5:require('@/assets/electric/iron/5.jpg'),
        car1:require('@/assets/electric/car/1_01.jpg'),
        car2:require('@/assets/electric/car/1_02.jpg'),
        car3:require('@/assets/electric/car/1_03.jpg'),
        car4:require('@/assets/electric/car/1_04.jpg'),
        car5:require('@/assets/electric/car/1_05.jpg'),
        car6:require('@/assets/electric/car/2_01.jpg'),
        car7:require('@/assets/electric/car/2_02.jpg'),
        car8:require('@/assets/electric/car/2_03.jpg'),
        car9:require('@/assets/electric/car/2_04.jpg'),
        car10:require('@/assets/electric/car/2_05.jpg'),
        car11:require('@/assets/electric/car/2_06.jpg'),
        car12:require('@/assets/electric/car/3_01.jpg'),
        car13:require('@/assets/electric/car/3_02.jpg'),
        car14:require('@/assets/electric/car/3_03.jpg'),
        home1:require('@/assets/electric/home/1_01.jpg'),
        home2:require('@/assets/electric/home/1_02.jpg'),
        home3:require('@/assets/electric/home/1_03.jpg'),
        home4:require('@/assets/electric/home/1_04.jpg'),
        home5:require('@/assets/electric/home/1_05.jpg'),
        home6:require('@/assets/electric/home/2_01.jpg'),
        home7:require('@/assets/electric/home/2_02.jpg'),
        home8:require('@/assets/electric/home/2_03.jpg'),
        home9:require('@/assets/electric/home/2_04.jpg'),
        home10:require('@/assets/electric/home/2_05.jpg'),
        home11:require('@/assets/electric/home/2_06.jpg'),
        home12:require('@/assets/electric/home/3_01.jpg'),
        home13:require('@/assets/electric/home/3_02.jpg'),
        home14:require('@/assets/electric/home/3_03.jpg'),
        home15:require('@/assets/electric/home/3_04.jpg'),
      }
    },
    mounted() {
        console.log(this.$route.query)
        this.index=parseInt(this.$route.query.index)
        console.log(this.index)
    },
    methods: {
      goElectric(v,k){
        this.index=k
      },
      next(){
        if(this.index === 18){
          this.index = 18
          document.querySelector('#top').scrollIntoView(true)
        }else{
          this.index = this.index + 1
          document.querySelector('#top').scrollIntoView(true)
        }
      },
      pre(){
        if(this.index === 0){
          this.$router.push({path:"/productCenter",query:{contActive:38}})
          document.querySelector('#top').scrollIntoView(true)
        }else{
          this.index = this.index - 1
          document.querySelector('#top').scrollIntoView(true)
        }
      },
      back(){
        this.$router.push({path:"/productCenter",query:{contActive:4}})
      },
      back2(){
        this.$router.push({path:"/productCenter",query:{contActive:27}})
      },
      showActive(val){
        this.contActive=val
        // console.log(this.contActive)
      },
      showIndex(val){
        this.currentIndex=val
        console.log(this.currentIndex)
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  
  .home {
    margin: 0;
    padding: 0;
    .box{
      width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      .left{
        width:20%;
        margin-top:50px;
        position: relative;
        .title{
          height:60px;
          line-height:60px;
          border-bottom: 1px solid #eee;
          font-size:30px;
          margin-bottom:20px;
        }
        .list{
          height:40px;
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
      }
      .right{
        width:70%;
        margin-top:50px;
        padding:0 50px;
        margin-right:10%;
        position: relative;
        .title{
            height:60px;
            line-height:60px;
            border-bottom: 1px solid #eee;
            font-size:30px;
            margin-bottom:20px;
        }
        .back{
          position: absolute;
          top:30px;
          right:10%;
          cursor: pointer;
          font-size: 16px;
        }
        .content{
          width: 100%;
          padding:50px 0;
          .image{
            width:70%;
            margin-left:15%;
          }
          .ee{
            width:50%;
            margin-left:25%;
          }
          .cz{
            width:80%;
            margin-left:10%;
          }
        }
        .page{
          width:100%;
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          .pre{
            padding:10px 15px;
            font-size:14px;
            background-color: #f8f6f6;
            border-radius: 5px;
            cursor: pointer;
          }
          .next{
            padding:10px 15px;
            font-size:14px;
            background-color: #f8f6f6;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  </style>
  