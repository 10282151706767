<template>
  <div id="nav-bar">
    <div class="container nav-bar">
      <div class="nav-ul">
        <div class="nav-list" v-for="(value,key) in nav" :key="key" :class="{navActive:currentIndex===key}" @click="goNav(value,key)" >
          <span>{{value.name}}</span>
          <div class="nav-list-prop" v-if="value.child.length>0">
            <div class="nav-ul">
              <div class="nav-list-child" v-for="(v,k) in value.child" :key="k" @click="goList(v,k)">{{v.name}}</div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: "pcNav",
        components: {},
        props:['index'],
        data(){
            return {
              currentIndex:this.index,
              nav:[
                {
                  id:1,
                  name:'首页',
                  href:"/",
                  active:1,
                  child:[]
                },{
                  id:2,
                  name:'关于谱地',
                  href:"/about",
                  active:0,
                  child:[
                    {id:1,name:"公司介绍",href:"/about"},
                    {id:2,name:"发展历程",href:"/about"},
                    {id:3,name:"经营理念",href:"/about"},
                    {id:4,name:"管理政策",href:"/about"},
                    {id:5,name:"资质荣誉",href:"/about"},
                  ]
                },{
                  id:3,
                  name:'核心竞争力',
                  href:"/core",
                  active:0,
                  child:[
                    {id:1,name:"产品研发",href:"/core"},
                    {id:2,name:"生产工艺",href:"/core"},
                    {id:3,name:"创新实践",href:"/core"},
                    {id:4,name:"项目管理",href:"/core"},
                    {id:5,name:"质量认证",href:"/core"},
                  ]
                },{
                  id:4,
                  name:'产品中心',
                  href:"/productCenter",
                  active:0,
                  child:[
                    {id:6,name:"电路板OEM代工",href:"/productCenter"},
                    {id:21,name:"物联网系统方案",href:"/productCenter"},
                    {id:26,name:"嵌入式硬件方案",href:"/productCenter"},
                    {id:4,name:"外贸优势小电器",href:"/productCenter"},
                    // {id:4,name:"汽车改装类产品",href:"/productCenter"},
                  ]
                },{
                  id:5,
                  name:'新闻中心',
                  href:"/news",
                  active:0,
                  child:[
                    {id:1,name:"公司新闻",href:"/news"},
                    {id:2,name:"行业新闻",href:"/news"},
                  ]
                },{
                  id:6,
                  name:'联系我们',
                  href:"/contact",
                  active:0,
                  child:[]
                }
              ]
            }
        },
        mounted(){
          console.log(this.index)
          this.currentIndex =this.index
          // console.log(this.currentIndex)
        },
        methods:{
          goNav(v,k){
            console.log(k)
            this.currentIndex=k
            this.$emit('showIndex',k)
            if(k===3){
              this.$router.push({path:v.href,query:{index:k,contActive:6}})
            }else{
              this.$router.push({path:v.href,query:{index:k,contActive:1}})
            }
            // console.log(this.currentIndex)
          },
          goList(v){
            this.$router.push({path:v.href,query:{contActive:v.id}})
            this.$emit('showActive',v.id)
            // console.log()
          }
        }
    }
</script>
<style lang="scss" scoped="scoped">
#nav-bar{
  width: 100vw;
  background-color: #202020;
  margin: 0 auto;
  display:inline-flex;
  justify-content: center;
}

.nav-bar{
  .nav-ul{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    .nav-list{
      padding: 15px 30px 15px 30px;
      color:white;
      position: relative;
      cursor: pointer;
      .nav-list-prop{
        position: absolute;
        background-color: rgba(32,32,32,0.8);
        left:0;
        top:50px;
        text-align: left;
        min-width: 160px;
        z-index:1000;
        overflow: hidden;
        height:0;
        .nav-ul{
          padding: 8px 0;
          display:block;
          .nav-list-child{
            line-height: 26px;
            color: #fff;
            font-size: 13px;
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: normal;
            white-space: nowrap;
            cursor: pointer;
          }
        }
        
      }
    }
    .nav-list:hover{

      background-color: #1BAA39;
      transition: all 800ms;
      -moz-transition: all 800ms;
      -webkit-transition: all 800ms;
      -o-transition: all 800ms;

      .nav-list-prop{
        height: auto;
      }
    }
  }
}

.navActive{
  background-color: #1BAA39;
}
.container{
    @media (min-width: 769px){
      width: 750px;
    }
    @media (min-width: 992px){
      width: 970px;
    }
    @media (min-width: 1200px){
      width: 1170px;
    }
  }
</style>