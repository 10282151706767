<template>
<div class="home">
    <pc-header></pc-header>
    <pc-nav @showActive="showActive"  @showIndex="showIndex" :index="currentIndex"></pc-nav>
    <div class="content">
        <div class="left">
            <div class="title">类别</div>
            <div class="list" v-for="(v,k) in list4" :key="k" @click="goElectric(v,k)">
                <div class="name">{{v.name}}</div>
            </div>
        </div>
        <div class="right">
            <div class="box" v-if="index==0">
                <div class="title">BLIS V1.0 并线辅助系统</div>
                <div class="title1">产品功能介绍</div>
                <div class="cont1">BLIS并线辅助系统（又名盲点监测系统），是一种在拥堵的交通情况下系统自动监测汽车两侧视觉盲区车辆情况的警示系统。它利用装在汽车后方包围内的防撞梁固定雷达进行探测，当有汽车进入你的后视镜盲区时，应用微型 处理器中央控制单元计算，并通过后视镜隐藏式指示灯或蜂鸣器进行提示报警。这一系统与后视镜一起可使司机快速评估变道的可行性。该系统在车速超过每小时20公里（可出厂设定）时激活，可对摩托车以上的任何类型的车辆做出反应，而且白天与黑夜工作得同样出色。</div>
                <img class="image" :src="image1"/>
                <div class="title1">产品工作原理</div>
                <div class="cont1">BLIS系统基于24GHZ毫米波感测技术，由主控制单元、探测单元、指示单元等组成，其通过接收探测单元采集的雷达反射信号，综合驾驶者的驾驶情况，如是否准备变道，是否已打转向灯等，进行处理、判断、提醒。</div>
                <img class="image" :src="image2"/>
                <div class="title1">产品组成部分</div>
                <div class="cont1">BLIS系统的组成由控制器、毫米波雷达、指示灯后视镜、蜂鸣器、OBD采样线、连接线束组成。</div>
                <img class="image" :src="image3"/>
                <div class="title1">产品技术参数</div>
                <div class="title2">1. 系统功能特点</div>
                <div class="cont1">BLIS两种工作方式：接入OBD线，本车车速在车20km/h以上时系统启动工作；不接入OBD线，系统在0km/h启动工作。系统工作时.如果车的侧后方有车或人靠近时，对应侧的指示灯会点亮，如果此时打开此侧转向灯，指示灯会闪烁，并发声三次。</div>
                <div class="title2">2. 指标参数</div>
                <div class="cont1">工作电压：9v-35v 最大工作电流：250mA</div>
                <div class="cont1">工作温度：-20℃-85℃ 车速大于20km/h时，系统开始工作</div>
                <div class="cont1">侧后方探测距离：8-12m 车速小于20km/h时，系统自动停止工作</div>
                <div class="title1">产品应用前景</div>
                <div class="cont1">国内交通路况日益复杂，快节奏的工作生活使得出行频繁，拥挤的道路，危险的马路杀手，大量的新手司机等，无意中加重了行车安全的风险，由此，车身安全系统在近年新车型上普及率逐步提高甚至成为标配，如EPS、ESP、ABS等各种行车安全保障系统，并线辅助系统应运而生，其解决了行车过车中后视盲点区域车辆碰撞的风险，使得变道时不再慌张。据我们评估统计，该产品用户接受认可程度80%以上，国内各大车厂2015年产销量2200万量中配置率不足2%，使得加装市场容量巨大，很快将呈现如同早期汽车导航一样爆发式增长。</div>
            </div>
            <div class="box" v-if="index==1">
                <div class="cont1">BLIS并线辅助系统是一款采用24GHz毫米波雷达技术的并线辅助系统，其主要作用是通过安装于车尾的毫米波雷达对车后方两侧3X12米区域进行监测，当有车辆靠近并进入监测范围时，系统主动进行声光预警。</div>
                <div class="title1">一、系统介绍</div>
                <div class="cont1">1、BLIS是一款采用24GHz毫米波雷达技术的并线辅助系统，其主要作用是通过安装于车尾的毫米波雷达对车后方两侧3X12米区域进行监测，当有车辆靠近并进入监测范围时，系统主动进行声光预警。此款由并线辅助系统主控、左右探测雷达、系统线束、报警灯或盲点后视镜组成。</div>
                <img class="image" :src="image4"/>
                <div class="cont1">2、系统功能特点：</div>
                <div class="cont1">a.本系统能通过车速控制自动启动工作，在车速大于20km/h时（可设定系统启动速度），系统开始工作。车速小于20km/h时，系统自动休眠。系统工作时.如果车的侧后方有车或人靠近时，对应侧的报警灯或后视镜上的指示灯会点亮，如果此时打开此侧转向灯，指示灯会闪烁，并发声三次。</div>
                <div class="cont1">b.BLIS并线辅助系统两种报警灯指示形式：①通用型报警灯；带盲点后视镜。</div>
                <div class="cont1">3、系统优点：</div>
                <div class="cont1">a.24GHz雷达精准探测；</div>
                <div class="cont1">b.专款专用车型支架，不影响原车结构；</div>
                <div class="cont1">c.免破线，安装简单方便；</div>
                <div class="cont1">d.供货原车车厂，品质保障。</div>
                <div class="title1">二、产品技术参数</div>
                <div class="cont1">工作电压：9V~35V</div>
                <div class="cont1">标准值：12V</div>
                <div class="cont1">最大工作电流：250mA</div>
                <div class="cont1">工作温度：-40℃~85℃</div>
                <div class="cont1">侧后方探测距离：8-12m</div>
                <div class="cont1">系统启动速度：20Km/h（接入OBD）</div>
            </div>
            <div class="box" v-if="index==2">
                <div class="title1">一、系统介绍</div>
                <div class="cont1">1、BLIS是一款采用24GHz毫米波雷达技术的并线辅助系统，其主要作用是通过安装于车尾的毫米波雷达对车后方两侧3X12米区域进行监测，当有车辆靠近并进入监测范围时，系统主动进行声光预警。此款由雷达、系统总线、蜂鸣器、左右转报警灯或盲点后视镜组成。</div>
                <img class="image" :src="image5"/>
                <div class="cont1">2、BLIS系统工作方式：</div>
                <div class="cont1">a.汽车启动则系统启动，系统启动后报警灯闪烁三次并伴随着三声“滴滴滴”声响代表系统自检OK；</div>
                <div class="cont1">b.当汽车速度≥0km/h时，汽车后方有物体靠近雷达，对应侧的报警灯亮起，提醒驾驶员后方有物体靠近；</div>
                <div class="cont1">c.此时打开汽车左转向灯/右转向灯/危险警报灯，此时后方物体靠近对应侧雷达，对应侧报警灯闪烁亮，蜂鸣器“滴滴滴”报警，提醒驾驶员此时变道存在危险</div>
                <div class="cont1">系统连接示意图：</div>
                <div class="cont1">①：探测雷达，②系统总线，③右转信号线，④左转信号线，⑤地线，⑥电源线，⑦蜂鸣器，⑧主驾驶报警灯线，⑨副驾驶报警灯线（⑧⑨可换成盲点后视镜）。</div>
                <img class="image" :src="image6"/>
                <div class="title1">二、系统功能特点</div>
                <div class="cont1">本系统将功能控制部分与雷达集成，汽车启动则系统启动，汽车熄火则系统关闭 ，当系统启动后，在汽车两侧后方7-12mm的范围内，如有后方有车辆靠近汽车，则系统报警灯会常亮，此时如果打开对应侧转向灯则系统报警灯会由常亮变为闪烁并伴随着三声“滴滴滴”报警声，直到后方汽车离开汽车盲区范围后，报警灯熄灭。</div>
                <div class="title1">三、指标参数</div>
                <div class="cont1">工作电压：9V~35V</div>
                <div class="cont1">最大工作电流：250mA</div>
                <div class="cont1">工作温度：-20-+85℃</div>
                <div class="cont1">侧后方探测距离：7-12m</div>
                <div class="cont1">报警精度：机动车98%，非机动车95%</div>
            </div>
            <div class="box" v-if="index==3">
                <div class="title">无线并线辅助系统</div>
                <div class="title1">一、系统介绍</div>
                <div class="cont1">1、系统工作原理</div>
                <div class="cont1">系统主要是由24GHz毫米波探测雷达、无线通信盒、无线控制盒、蜂鸣器及报警灯组成，当探测雷达检测到后视镜盲区内有靠近物体时，将信号传输至无线控制盒，由无线控制盒将信息进行处理后传输至对应侧的无线通信盒和蜂鸣器，再由无线通信盒将信号传输至报警灯。</div>
                <div class="cont1">2、系统功能特点：</div>
                <div class="cont1">a.汽车启动，则系统并线辅助（BLIS）功能启动，此时系统检测到车的侧后方有车或人靠近时，对应侧的报警灯或后视镜上的指示灯会点亮，如果此时打开此侧转向灯，指示灯会闪烁，并发声三次。</div>
                <div class="cont1">b.车辆熄火后，系统开门预警（DOW）功能启动，在15s的时间内，可对后方靠近行人和车辆进行灯光闪烁预警，即此时后方有车辆靠近，则系统对应侧的报警常亮警示车上人员此时开门可能会有危险。</div>
                <div class="cont1">3、系统优点：</div>
                <div class="cont1">a.24GHz雷达精准探测；</div>
                <div class="cont1">b.并线辅助功能+开门预警功能双重保险；</div>
                <div class="cont1">c.信号无线传输，科技前沿；</div>
                <div class="cont1">d.安装便捷。</div>
                <div class="title1">二、指标参数</div>
                <div class="cont1">工作电压：9V~24V</div>
                <div class="cont1">保准值：12V</div>
                <div class="cont1">最大工作电流：400mA</div>
                <div class="cont1">工作温度：-20℃~75℃</div>
                <div class="cont1">侧后方探测距离：8-12m</div>
                <div class="cont1">系统启动速度：0Km/h</div>
                <img class="image" :src="image7"/>
            </div>
            <div class="box" v-if="index==4">
                <div class="title1">一、系统功能介绍</div>
                <div class="cont1">1、BSD功能说明</div>
                <img class="image2" :src="image8"/>
                <div class="cont1">BSD1和BSD2为左右两边的盲区探测区域，当同向行驶的B车进入A车（Va≥24KM/h）的BSD1区域时，A车左侧盲区指示灯进行报警；当A车（Va-Vc≤40KM/h并且Vc≥10KM/h）超越C车时，C车在BSD2区域，A车右侧盲区指示灯进行报警，否则不报警。</div>
                <div class="cont1">2、DOW功能说明：</div>
                <img class="image2" :src="image9"/>
                <div class="cont1"> DOW1和DOW2为开门预警区域，当有车辆、行人等进入开门预警区域内，并且靠近车辆A，系统会发出灯光报警。具体报警方式见下表</div>
                <div class="cont1">（注：报警距离远近取决于车/人等靠近的速度）</div>
                <div class="cont1">3、RCTA功能说明</div>
                <img class="image2" :src="image10"/>
                <div class="cont1">RCTA1和RCTA2为倒后方横向车预警区域，当有车辆进入RCTA预警区域内靠近车辆A时，系统会发出灯光常亮+声音报警。</div>
                <div class="title1">二、产品特点</div>
                <div class="cont1">a.采用24GHz单发双收探测雷达，实现精准探测；</div>
                <div class="cont1">b.系统同时具有BSD（盲区检测）、DOW（开门预警）、RCTA（后方来车横向预警）等功能；</div>
                <div class="title1">三、产品图片</div>
                <img class="image2" :src="image11"/>
            </div>
        </div>
    </div>
    <pc-footer></pc-footer>
</div>
</template>
<script>
import PcHeader from "@/components/pc/header.vue"
import PcNav from "@/components/pc/nav.vue"
import PcFooter from "@/components/pc/footer.vue"
export default {
    components: {PcHeader,PcNav,PcFooter},
    data(){
        return{
            list4:[
                {
                    src:require('@/assets/three/1.png'),
                    name:"BLIS V1.0并线辅助系统"
                },
                {
                    src:require('@/assets/three/2.png'),
                    name:"BLIS V1.1并线辅助系统"
                },
                {
                    src:require('@/assets/three/3.png'),
                    name:"BLIS V1.2并线辅助系统"
                },
                {
                    src:require('@/assets/three/4.png'),
                    name:"BLIS V1.3并线辅助系统"
                },
                {
                    src:require('@/assets/three/5.png'),
                    name:"BLIS V2.0盲区监测系统"
                }
            ],
            index:0,
            image1:require('@/assets/blis1.png'),
            image2:require('@/assets/blis2.png'),
            image3:require('@/assets/blis3.png'),
            image4:require('@/assets/blis4.png'),
            image5:require('@/assets/blis5.png'),
            image6:require('@/assets/blis6.png'),
            image7:require('@/assets/blis7.png'),
            image8:require('@/assets/mq1.png'),
            image9:require('@/assets/mq2.png'),
            image10:require('@/assets/mq3.png'),
            image11:require('@/assets/mq4.png')
        }
    },
    mounted(){
        console.log(this.$route.query)
        this.index=this.$route.query.index
        console.log(this.index)
    }
}
</script>
<style lang="scss" scoped>
.home{
    margin:0;
    padding:0;
    .content{
      width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      .left{
        width:20%;
        margin-top:40px;
        .title{
          height:60px;
          line-height:60px;
          border-bottom: 1px solid #eee;
          font-size:30px;
          margin-bottom:20px;
        }
        .list{
          height:40px;
          line-height:40px;
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
      }
      .right{
        width:80%;
        .box{
            width: 100%;
            padding:50px 0px 50px 50px;
            margin:0 auto;
            .title{
                width:100%;
                font-size:30px;
                font-weight: 600;
                text-align: center;
                letter-spacing: 2px;
                margin:0 auto;
            }
            
            .title1{
                width:100%;
                font-size:26px;
                font-weight: 500;
                text-align: left;
                margin:30px auto;
            }
            .title2{
                width:100%;
                font-size:22px;
                font-weight: 500;
                text-align: left;
                margin:50px auto;
            }
            .cont1{
                width:100%;
                font-size:18px;
                text-align: left;
                padding:0 10px;
                margin:20px auto;
            }
            .image{
                width:70%;
                margin-left:15%;
                margin-top:20px;
            }
            .image2{
                width:70%;
                margin-left:15%;
                margin-top:20px;
            }
        }
      }
    }
}
</style>